import { Button, ButtonProps } from '@mui/material'
import React from 'react'
import { Icon } from '@mdi/react'
import { mdiArrowLeft } from '@mdi/js'

export type BackButtonProps = ButtonProps
export const BackButton = (props: BackButtonProps) => {
  const { sx, ...rest } = props
  return (
    <Button
      startIcon={<Icon path={mdiArrowLeft} size={1} />}
      sx={{
        fontSize: '20px',
        lineHeight: '20px',
        textTransform: 'none',
        height: '26px',
        alignItems: 'center',
        p: 0,
        ...sx,
      }}
      {...rest}
    >
      zurück
    </Button>
  )
}
