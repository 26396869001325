import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ProgressBar } from '../components/ProgressBar'
import { OptionButton } from '../components/buttons/OptionButton'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { NavigationStateType, selectNavigation, setStrategyVariant } from '../redux/reducers/navigation'
import { PORTFOLIO_DATA } from '../PORTFOLIO_CONFIG'
// import { scrollToTop } from '../utils/utils'
import { appendValuesInGoogleSheet } from '../utils/api'
import { CBackdrop } from '../components/Backdrop'

const ALPHA_ENUMERATORS = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')

export const ProfessionalVariants = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const store = useAppSelector(selectNavigation)
  const [finished, setFinished] = React.useState(false)

  const handleClickNext = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement> & { target: { name: NavigationStateType['strategy_variant'] } }) => {
      const name = e?.target?.name ?? null
      if (!name) return
      setFinished(true)
      dispatch(setStrategyVariant(name))
      await appendValuesInGoogleSheet(store, name)
      const urlParams = `?ziel=${store?.objective}&cat=${store?.strategy}&variant=${name}&einmalig=${store.one_time_invest_amount ?? 0}&monatlich=${store.monthly_invest_amount?? 0}`
      window.location.href = `/result/${urlParams}`
    },
    [navigate, dispatch, store]
  )

  return (
    <Box>
      <ProgressBar progress={80} label="Schwankungsgrad festlegen" />
      <Typography variant="h2" paddingTop={'40px'}>
        Profi Varianten
      </Typography>
      <Stack gap={'12px'} mt={'55px'}>
        {PORTFOLIO_DATA.portfolios
          .filter((pf) => pf.type === 'professionals')
          .map((pf, pIdx) => (
            <OptionButton
              key={pIdx}
              option={ALPHA_ENUMERATORS[pIdx]}
              secondaryLabel={pf.description}
              name={pf.id}
              onClick={handleClickNext}
            >
              {pf.name}
            </OptionButton>
          ))}
      </Stack>
      <CBackdrop open={finished} />
    </Box>
  )
}
