import React from 'react'
import { Button, ButtonProps, Theme, alpha, useTheme, Box, Stack } from '@mui/material'
import Icon from '@mdi/react'
import { mdiArrowRight } from '@mdi/js'

const defaultOptionStyle = (theme: Theme): Partial<ButtonProps['sx']> => ({
  borderRadius: '4px',
  width: '100%',
  minHeight: 64,
  fontWeight: 700,
  fontSize: '20px',
  border: '1px solid #2860F6',
  background: alpha(theme.palette.primary.main, 0.2),
  textTransform: 'none',
  boxShadow: 'none',
  '&: hover': {
    // eslint-disable-next-line no-useless-computed-key
    ['@media(pointer: fine)']: {
      boxShadow: 'none',
      background: alpha(theme.palette.primary.main, 0.6),
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media(pointer: coarse)']: {
      boxShadow: 'none',
      background: alpha(theme.palette.primary.main, 0.2),
    },
  },
  color: theme.palette.primary.main,
  justifyContent: 'flex-start',
  gap: '20px',
  display: 'flex',
  pt: '20px',
  pb: '20px',
})

export type PortfolioOptionButtonProps = ButtonProps & {
  children?: React.ReactNode
  secondaryLabel: React.ReactNode
}

export const PortfolioOptionButton = (props: PortfolioOptionButtonProps) => {
  const { children, sx, secondaryLabel, ...rest } = props
  const theme = useTheme()

  const optionStyles = React.useMemo(() => ({ ...defaultOptionStyle(theme), ...sx }), [sx, theme])

  return (
    <Button sx={optionStyles} variant="contained" {...rest}>
      <Stack alignItems="flex-start" sx={{ pointerEvents: 'none' }}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Box sx={{ lineHeight: '26px', fontSize: '20px' }}>{children}</Box>
          <Icon path={mdiArrowRight} size={1} />
        </Stack>
        {secondaryLabel && (
          <Box
            sx={{
              fontSize: '14px',
              color: '#1A2C58',
              textAlign: 'left',
              lineHeight: '18px',
              mt: '11px',
              fontWeight: 400,
            }}
          >
            {secondaryLabel}
          </Box>
        )}
      </Stack>
    </Button>
  )
}
