import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    content: Palette['primary']
  }
  interface PaletteOptions {
    content: PaletteOptions['primary']
  }
}
declare module '@mui/material/styles/createTypography' {
  interface Typography {
    smallPrint: TypographyStyle
    customSize: TypographyStyle
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    smallPrint: TypographyStyleOptions
    customSize: TypographyStyleOptions
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    smallPrint: true
    customSize: true
  }
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
}

// const primaryColor = '#009688'
export const muiLightSiteTheme = createTheme({
  breakpoints,
  palette: {
    primary: {
      main: '#2860F6',
    },
    secondary: {
      main: '#00D1FF',
    },
    content: {
      main: '#1A2C58',
    },
    success: {
      main: '#2fb100',
    },
    mode: 'light',
  },
  components: {
    // Name of the component
    MuiLink: {
      styleOverrides: {
        // Name of the slot
        root: {
          cursor: 'pointer',
        },
      },
    },
  },

  typography: {
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#1A2C58',
      [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
        fontSize: '18px',
        lineHeight: '23px',
      },
    },
    smallPrint: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#1A2C58',
    },
    customSize: {
      color: '#1A2C58',
      display: 'block',
    },
    caption: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '23px',
      color: '#1A2C58',
    },
    h1: {
      fontWeight: 700,
      fontSize: '38px',
      lineHeight: '49px',
      color: '#1A2C58',
      [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
        fontSize: '40px',
        lineHeight: '52px',
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '41px',
      color: '#1A2C58',
      [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
        fontSize: '36px',
        lineHeight: '47px',
      },
    },
    h3: {
      fontWeight: 700,
      fontSize: '26px',
      lineHeight: '34px',
      color: '#1A2C58',
    },
    fontFamily: "'Plus Jakarta Display', sans-serif",
  },
})