import React from 'react'
import { Button, ButtonProps } from '@mui/material'

const defaultPrimaryStyle: Partial<ButtonProps['sx']> = {
  borderRadius: '2px',
  width: "100%",
  height: 64,
  fontWeight: 400,
  fontSize: '26px',
  textTransform: 'none',
  boxShadow: 'none',
  '&: hover': {
    boxShadow: 'none',
  },
}

const defaultSecondaryStyle: Partial<ButtonProps>['sx'] = {
  fontSize: '18px',
  lineHeight: '21px',
}

export type ButtonType = 'primary' | 'secondary'

export type CButtonProps = Omit<ButtonProps, 'type'> & {
  children?: React.ReactNode
  type?: ButtonType
}

export const CButton = React.forwardRef((props: CButtonProps, ref: any) => {
  const { children, sx, type: typeIn, ...rest } = props
  const type = typeIn ?? 'primary'
  const secondaryStyles = React.useMemo(() => (type === 'secondary' ? defaultSecondaryStyle : {}), [type])
  const primaryStyles = React.useMemo(
    () => ({ ...defaultPrimaryStyle, ...secondaryStyles, ...sx }),
    [sx, secondaryStyles]
  )

  return (
    <Button sx={primaryStyles} variant={type === 'primary' ? 'contained' : 'outlined'} {...rest} ref={ref}>
      {children}
    </Button>
  )
})
CButton.displayName = 'CButton'
