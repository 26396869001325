import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

export const Banner = () => {
  const theme = useTheme()
  const isMinXlWidth = useMediaQuery(theme.breakpoints.up('xl'))
  const isMinLgWidth = useMediaQuery(theme.breakpoints.up('lg'))
  const isMinSmWidth = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box
      sx={{
        // maxWidth: 375,
        bgcolor: 'content.main',
        textTransform: 'uppercase',
        color: '#fff',
        letterSpacing: '0.22em',
        lineHeight: '15px',
        fontSize: 13,
        pt: '3px',
        pb: '5px',
        pl: '28px',
      }}
    >
      <Container
        maxWidth={isMinSmWidth && !isMinLgWidth ? 'sm' : isMinLgWidth && !isMinXlWidth ? 'md' : 'lg'}
        disableGutters
      >
        Orientierungswissen
      </Container>
    </Box>
  )
}
