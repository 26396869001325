import React from 'react'
import { Typography, Box, Link, BoxProps } from '@mui/material'

export const RiskAdvisoryHistory = (props: BoxProps) => (
  <>
    <Box {...props}>
      <Typography color="#8A95AD" component="span" fontWeight={700} variant="smallPrint" sx={{ lineHeight: '15px' }}>
        Risikohinweis:{` `}
      </Typography>
      <Typography color="#8A95AD" component="span" variant="smallPrint" sx={{ lineHeight: '15px' }}>
        Historische Wertentwicklungen sind kein verlässlicher Indikator für zukünftige Wertentwicklungen. Geldanlagen an
        Kapitalmärkten sind mit Risiken verbunden.
      </Typography>
    </Box>

    <Link
      color="primary"
      variant="smallPrint"
      paddingTop={'8px'}
      href="https://villiam-landingpage.webflow.io/risikohinweis"
      sx={{ textDecoration: 'underline', display: 'block', width: 'max-content', maxWidth: '100%' }}
      target="_blank"
      rel="noopener noreferrer"
    >
      Bitte beachten Sie unsere Risikohinweise.
    </Link>
  </>
)
