import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CButton } from '../buttons/Button'
import { RiskAdvisoryProjection } from './RiskAdvisoryProjection'

export type PortfolioResultAssetCardProps = {
  header: string
  item: React.ReactNode
  itemLinkHref: string
  itemDescription: React.ReactNode
  regulationNotice?: React.ReactNode
  buttonLabel: string
  cardImage?: string
  specificButtonLink?: string
}
export const PortfolioResultAssetCard = (props: PortfolioResultAssetCardProps) => {
  const { header, item, itemLinkHref, itemDescription, regulationNotice, buttonLabel, cardImage, specificButtonLink } =
    props
  const navigate = useNavigate()

  const handleClickFindInstruments = React.useCallback(() => {
    if (specificButtonLink) navigate(specificButtonLink)
    else window.open(itemLinkHref, '_blank', 'noopener,noreferrer')
  }, [itemLinkHref, specificButtonLink, navigate])

  return (
    <Box border="1px solid #E0E0E0" pr="18px" pl="18px" pt="30px" pb="35px">
      <Typography sx={{ textDecoration: 'underline' }}>{header}</Typography>
      {cardImage && (
        <Box paddingTop="32px">
          <img src={`${process.env.PUBLIC_URL}/images/${cardImage}`} alt="asset" />
        </Box>
      )}
      <Link
        paddingTop="32px"
        color="primary"
        sx={{ textDecoration: 'underline', display: 'block', width: '100%' }}
        href={itemLinkHref}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item}
      </Link>
      <Typography paddingTop="5px">{itemDescription}</Typography>
      {regulationNotice && (
        <Typography paddingTop="32px" variant="smallPrint" component="div" color="#8A95AD">
          {regulationNotice}
        </Typography>
      )}
      <CButton
        sx={{ mt: '36px', width: '100%', fontSize: '18px', lineHeight: '21px' }}
        onClick={handleClickFindInstruments}
      >
        {buttonLabel}
      </CButton>
      <RiskAdvisoryProjection />
    </Box>
  )
}
