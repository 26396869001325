/* There are 3 Product Types which determine the way a product is shown */

export type AnonymousInvestmentProductType = {
  id: string // for JS
  category: string // shown on Card and determines the realization text -> hard-coded per category and some specifics like Wertgrund
  nameAnonymized: string // displayed on Card when recommendation is not valid
  typeLabel: string
  furtherInfoLink: string
  descriptionAnonymized: string
  regulationNotice?: string
  buttonLabelAnonymized: string
  volatilityYTY: number
  years: { year: number; returnYty: number }[]
}
export type SpecificInvestmentProductType = {
  id: string
  category: string
  name: string // Product Name shown on PortfolioResultAssetCard
  typeLabel: string
  productLink: string
  description: string
  // regulationNotice?: string
  cardImage?: string
  buttonToLink?: string
  buttonLabel: string
  volatilityYTY: number
  years: { year: number; returnYty: number }[]
}

export type CategoryInvestmentProductType = {
  id: string
  category: string
  name: string
  typeLabel: string
  // productLink: string
  description: string
  regulationNotice?: string
  buttonLabel: string

  useAltText: true
  altText: string
  altLinkLabel: string
  altLinkHref: string
  volatilityYTY: number
  years: { year: number; returnYty: number }[]
}

export type AnonymousPortfolio = {
  id: string
  name: string
  description: string
  type: 'professionals'
  volatilityYTY: number
  composition: { id: string; share: number }[]
}

export type SpecificPortfolio = Omit<AnonymousPortfolio, 'type'> & {
  nameInspirationVariants: string
  subtitle: string
  sources: string[]
  type: 'inspirations'
}

export type PortfolioType = AnonymousPortfolio | SpecificPortfolio
export type PortfolioPositionType = { id: string; share: number }
export type PortfolioIdType = PortfolioType['id']
export type InvestmentProductType =
  | CategoryInvestmentProductType
  | SpecificInvestmentProductType
  | AnonymousInvestmentProductType
export type ComposedPortfolioPositionType = PortfolioPositionType & InvestmentProductType
export type ComposedPortfolio = Omit<PortfolioType, 'composition'> & { composition: ComposedPortfolioPositionType[] }

export const PORTFOLIO_DATA: {
  investmentProducts: InvestmentProductType[]
  portfolios: PortfolioType[]
} = {
  investmentProducts: [
    // current products for type===professional
    {
      id: 'germanBondsXtrackers',
      name: 'Xtrackers Germany Government Bond UCITS ETF',
      category: 'Anleihen',
      nameAnonymized: 'Bundesanleihen Deutschland',
      typeLabel: 'Anleihen', // shown on Card, portfolio composition and if typeLabel="Aktien" result will show the ETF recommendations
      furtherInfoLink: 'https://www.bundesbank.de/de/service/bundeswertpapiere/kurse-und-renditen', // link for anonymized card
      // productLink: '',
      // description: "",
      descriptionAnonymized: `Finden Sie die für Ihren Anlagezeitraum passende Anleihe.\n\nWählen Sie eine Bundesanleihe mit positiver Rendite, die zu Ihrem Anlagezeitraum passt.`, // descr for anonymized card
      regulationNotice: `Aufgrund von regulatorischen Einschränkungen dürfen wir Ihnen an dieser Stelle keine konkreten Anleihen
        empfehlen. Bitte wählen Sie eine für Sie passende Anleihe bei der Bundesbank.`, // regulation notice on anonymized Card
      buttonLabel: 'Mehr Informationen zur Anleihe', // button label on card
      buttonLabelAnonymized: 'Aktuelle Anleihen finden', // button label on anonymized card
      // avgReturnYTY: 0.0198,
      volatilityYTY: 0.0372, // product's avg YTY, shown on PortfolioResultAsset
      // yearly performance data
      years: [
        {
          year: 2012,
          returnYty: 0.042,
        },
        {
          year: 2013,
          returnYty: -0.023,
        },
        {
          year: 2014,
          returnYty: 0.103,
        },
        {
          year: 2015,
          returnYty: 0.002,
        },
        {
          year: 2016,
          returnYty: 0.041,
        },
        {
          year: 2017,
          returnYty: -0.017,
        },
        {
          year: 2018,
          returnYty: 0.021,
        },
        {
          year: 2019,
          returnYty: 0.029,
        },
        {
          year: 2020,
          returnYty: 0.028,
        },
        {
          year: 2021,
          returnYty: -0.028,
        },
      ],
    },
    {
      id: 'globalStocksMsci',
      name: 'Lyxor MSCI World UCITS ETF',
      nameAnonymized: 'ETF mit weltweiten Aktien',
      category: 'Weltweite Aktien',
      typeLabel: 'Aktien',
      furtherInfoLink:
        'https://www.justetf.com/de/find-etf.html?assetClass=class-equity&groupField=index&region=World&sector=none&equityStrategy=Dividend&theme=none',
      productLink: 'https://www.justetf.com/en/etf-profile.html?isin=LU1781541179',
      descriptionAnonymized: `Suchen Sie einen ETF, der weltweit in Unternehmen aus Industrieländern investiert.`,
      regulationNotice: `Aufgrund von regulatorischen Einschränkungen dürfen wir Ihnen an dieser Stelle keinen konkreten ETF empfehlen.
        Bitte wählen Sie einen für Sie passenden ETF bei just-etf.de.`,
      buttonLabel: 'Mehr Informationen zum ETF',
      buttonLabelAnonymized: 'Jetzt Ihren ETF wählen',
      volatilityYTY: 0.1183,
      years: [
        {
          year: 2012,
          returnYty: 0.146,
        },
        {
          year: 2013,
          returnYty: 0.273,
        },
        {
          year: 2014,
          returnYty: 0.058,
        },
        {
          year: 2015,
          returnYty: -0.006,
        },
        {
          year: 2016,
          returnYty: 0.067,
        },
        {
          year: 2017,
          returnYty: 0.223,
        },
        {
          year: 2018,
          returnYty: -0.092,
        },
        {
          year: 2019,
          returnYty: 0.285,
        },
        {
          year: 2020,
          returnYty: 0.162,
        },
        {
          year: 2021,
          returnYty: 0.224,
        },
      ],
    },
    {
      id: 'globalStocksMsciEuro',
      name: 'MSCI World Euro ETF',
      category: 'Weltweite Aktien',
      nameAnonymized: 'ETF mit weltweiten Aktien',
      typeLabel: 'Aktien',
      productLink: 'https://www.justetf.com/en/etf-profile.html?isin=LU0392494562',
      description: `Der Aktienindex MSCI World bündelt die nach Börsenwert größten Unternehmen der Industriestaaten.`,
      descriptionAnonymized: `Suchen Sie einen ETF, der weltweit in Unternehmen aus Industrieländern investiert.`,
      regulationNotice: `Aufgrund von regulatorischen Einschränkungen dürfen wir Ihnen an dieser Stelle keinen konkreten ETF empfehlen.
        Bitte wählen Sie einen für Sie passenden ETF bei just-etf.de.`,
      furtherInfoLink:
        'https://www.justetf.com/de/find-etf.html?assetClass=class-equity&groupField=index&region=World&sector=none&equityStrategy=Dividend&theme=none',
      buttonLabel: 'Mehr Informationen zum ETF',
      buttonLabelAnonymized: 'Jetzt Ihren ETF wählen',
      volatilityYTY: 0.0994,
      years: [
        {
          year: 2011,
          returnYty: -0.026,
        },
        {
          year: 2012,
          returnYty: 0.138,
        },
        {
          year: 2013,
          returnYty: 0.21,
        },
        {
          year: 2014,
          returnYty: 0.193,
        },
        {
          year: 2015,
          returnYty: 0.102,
        },
        {
          year: 2016,
          returnYty: 0.105,
        },
        {
          year: 2017,
          returnYty: 0.073,
        },
        {
          year: 2018,
          returnYty: -0.043,
        },
        {
          year: 2019,
          returnYty: 0.298,
        },
        {
          year: 2020,
          returnYty: 0.061,
        },
      ],
    },
    {
      id: 'globalStocksMsciValue',
      name: 'MSCI World Value ETF',
      category: 'Weltweite Aktien',
      nameAnonymized: 'ETF mit weltweiten Aktien',
      typeLabel: 'Aktien',
      productLink: 'https://www.justetf.com/en/etf-profile.html?isin=IE00BP3QZB59',
      description: `Der Aktienindex MSCI World selektiert Werte aus dem MSCI World nach einer Value-Strategie.\n\nDabei werden die folgenden Kriterien beachtet: Kurs-Buchwertverhältnis (KBV), Kurs zu künftigen Gewinnerwartungen (KGV) und Ausschüttungsquote.`,
      descriptionAnonymized: `Suchen Sie einen ETF, der weltweit in Unternehmen aus Industrieländern investiert.`,
      regulationNotice: `Aufgrund von regulatorischen Einschränkungen dürfen wir Ihnen an dieser Stelle keinen konkreten ETF empfehlen.
        Bitte wählen Sie einen für Sie passenden ETF bei just-etf.de.`,
      furtherInfoLink: 'https://www.justetf.com/en/how-to/invest-in-value-etfs.html',
      buttonLabel: 'Mehr Informationen zum ETF',
      buttonLabelAnonymized: 'Jetzt Ihren ETF wählen',
      volatilityYTY: 0.1437,
      years: [
        {
          year: 2011,
          returnYty: -0.1157,
        },
        {
          year: 2012,
          returnYty: 0.1428,
        },
        {
          year: 2013,
          returnYty: 0.3192,
        },
        {
          year: 2014,
          returnYty: 0.0398,
        },
        {
          year: 2015,
          returnYty: -0.0328,
        },
        {
          year: 2016,
          returnYty: 0.0813,
        },
        {
          year: 2017,
          returnYty: 0.2215,
        },
        {
          year: 2018,
          returnYty: -0.1393,
        },
        {
          year: 2019,
          returnYty: 0.1899,
        },
        {
          year: 2020,
          returnYty: -0.0397,
        },
      ],
    },
    {
      id: 'materialAssetWertgrund',
      name: 'WERTGRUND WohnSelect D',
      category: 'offener Wohnimmobilien Fonds Deutschland',
      typeLabel: 'Sachwerte',
      productLink: 'https://www.wohnselect.de/',
      buttonToLink: '/active-fond-criteria',
      description: `Investition in Wohnimmobilien in Deutschland an ausgesuchten Standorten mit aktivem Mietmanagement`,
      buttonLabel: 'Mehr Informationen zum Fonds',
      volatilityYTY: 0.052,
      cardImage: 'wertgrund.svg',
      years: [
        {
          year: 2011,
          returnYty: 0.04139,
        },
        {
          year: 2012,
          returnYty: 0.0432,
        },
        {
          year: 2013,
          returnYty: 0.0625,
        },
        {
          year: 2014,
          returnYty: 0.074,
        },
        {
          year: 2015,
          returnYty: 0.0514,
        },
        {
          year: 2016,
          returnYty: 0.0385,
        },
        {
          year: 2017,
          returnYty: 0.2142,
        },
        {
          year: 2018,
          returnYty: 0.1002,
        },
        {
          year: 2019,
          returnYty: 0.0631,
        },
        {
          year: 2020,
          returnYty: 0.0214,
        },
      ],
    },
    {
      id: 'europeanBondsBarclays',
      name: 'Bloomberg Global Aggregate Bond UCITS ETF',
      category: 'Festverzinsliche Euro-Anleihen',
      typeLabel: 'Anleihen',
      productLink:
        'https://www.justetf.com/de/etf-profile.html?query=barclays++aggregate++global&groupField=index&from=search&isin=IE00BF1QPH33#overview',
      description: `Der ETF bietet Zugang zu Anleihen in Industrie- und Schwellenländern weltweit. Der Index beinhaltet alle Laufzeiten. Rating: Investment Grade. Währungsgesichert in US-Dollar (USD).`,
      buttonLabel: 'Jetzt Informieren & Investieren',
      buttonLabelAnonymized: 'Jetzt Ihren ETF wählen',
      volatilityYTY: 0.0045,
      years: [
        {
          year: 2012,
          returnYty: 0.00593875,
        },
        {
          year: 2013,
          returnYty: 0.00593875,
        },
        {
          year: 2014,
          returnYty: 0.00593875,
        },
        {
          year: 2015,
          returnYty: 0.00593875,
        },
        {
          year: 2016,
          returnYty: 0.00593875,
        },
        {
          year: 2017,
          returnYty: 0.00593875,
        },
        {
          year: 2018,
          returnYty: 0.00593875,
        },
        {
          year: 2019,
          returnYty: 0.00593875,
        },
        {
          year: 2020,
          returnYty: 0.00593875,
        },
        {
          year: 2021,
          returnYty: 0.00593875,
        },
      ],
    },
    {
      id: 'fixedDeposite',
      name: 'Festgeldanbieter bei finanztip.de finden',
      category: 'Festgeld',
      typeLabel: 'Festgeld',
      description: `Finden Sie den für Sie passenden Festgeldanbieter bei finanztip.de.\n\nWählen Sie die für Sie passende Laufzeit und Rendite.`,
      regulationNotice: `Aufgrund von regulatorischen Einschränkungen dürfen wir Ihnen an dieser Stelle keinen konkreten Abieter für Festgeld empfehlen. Bitte wählen Sie einen für Sie passenden Anbieter bei finanztip.de`,
      buttonLabel: 'Jetzt Festgeld finden',
      useAltText: true,
      altText: `Nach wie vor befinden wir uns in einem Niedrigzins-Umfeld. Deshalb empfehlen wir, als gute Alternative zu klassischen Renten, eine Festgeldanlage.\n\nDiese finden Sie hier:`,
      altLinkLabel: `https://www.finanztip.de/festgeld/`,
      altLinkHref: `https://www.finanztip.de/festgeld/`,
      volatilityYTY: 0.0045,
      years: [
        {
          year: 2012,
          returnYty: 0.00593875,
        },
        {
          year: 2013,
          returnYty: 0.00593875,
        },
        {
          year: 2014,
          returnYty: 0.00593875,
        },
        {
          year: 2015,
          returnYty: 0.00593875,
        },
        {
          year: 2016,
          returnYty: 0.00593875,
        },
        {
          year: 2017,
          returnYty: 0.00593875,
        },
        {
          year: 2018,
          returnYty: 0.00593875,
        },
        {
          year: 2019,
          returnYty: 0.00593875,
        },
        {
          year: 2020,
          returnYty: 0.00593875,
        },
        {
          year: 2021,
          returnYty: 0.00593875,
        },
      ],
    },
    {
      id: 'overnightMoney',
      name: 'Tagesgeldanbieter bei finanztip.de finden',
      category: 'Tagesgeld',
      typeLabel: 'Tagesgeld',
      description: `Finden Sie den für Sie passenden Tagesgeldanbieter bei finanztip.de.`,
      regulationNotice: `Aufgrund von regulatorischen Einschränkungen dürfen wir Ihnen an dieser Stelle keinen konkreten Abieter für Tagesgeld empfehlen. Bitte wählen Sie einen für Sie passenden Anbieter bei finanztip.de`,
      buttonLabel: 'Jetzt Tagesgeld finden',
      useAltText: true,
      altText: `Nach wie vor befinden wir uns in einem Niedrigzins-Umfeld. Deshalb empfehlen wir, als gute Alternative zu klassischen Renten, eine Tagesgeldanlage.\n\nDiese finden Sie hier:`,
      altLinkLabel: `https://www.finanztip.de/tagesgeld/`,
      altLinkHref: `https://www.finanztip.de/tagesgeld/`,
      productLink: `https://www.finanztip.de/tagesgeld/`,
      volatilityYTY: 0.0063,
      years: [
        {
          year: 2011,
          returnYty: 0.0155,
        },
        {
          year: 2012,
          returnYty: 0.0204,
        },
        {
          year: 2013,
          returnYty: 0.0116,
        },
        {
          year: 2014,
          returnYty: 0.007,
        },
        {
          year: 2015,
          returnYty: 0.0051,
        },
        {
          year: 2016,
          returnYty: 0.0037,
        },
        {
          year: 2017,
          returnYty: 0.0023,
        },
        {
          year: 2018,
          returnYty: 0.0018,
        },
        {
          year: 2019,
          returnYty: 0.0017,
        },
        {
          year: 2020,
          returnYty: 0.0014,
        },
      ],
    },
  ],
  portfolios: [
    {
      id: 'pro-20-80' as const,
      name: '20/80',
      description: 'Geringe Wertschwankungen sind wichtiger als hohe Renditechancen. ',
      type: 'professionals' as const,
      volatilityYTY: 0.03,
      composition: [
        {
          id: 'globalStocksMsci',
          share: 0.2,
        },
        {
          id: 'germanBondsXtrackers',
          share: 0.8,
        },
      ],
    },
    {
      id: 'pro-50-50' as const,
      name: '50/50',
      description: 'Angemessene Wertschwankungen sind genauso wichtig wie Renditechancen.',
      type: 'professionals' as const,
      volatilityYTY: 0.05,
      composition: [
        {
          id: 'globalStocksMsci',
          share: 0.5,
        },
        {
          id: 'germanBondsXtrackers',
          share: 0.5,
        },
      ],
    },
    {
      id: 'pro-80-20' as const,
      name: '80/20',
      description: 'Renditechancen sind wichtiger als geringe Wertschwankungen.',
      type: 'professionals' as const,
      volatilityYTY: 0.09,
      composition: [
        {
          id: 'globalStocksMsci',
          share: 0.8,
        },
        {
          id: 'germanBondsXtrackers',
          share: 0.2,
        },
      ],
    },
    {
      id: 'insp-all-time-winner' as const,
      name: 'Der Seriensieger',
      nameInspirationVariants: 'Den Seriensieger',
      description: `Der “All-time-Winner” unter den Robo-Portfolios\n\nGewählt von Kunden, die auf bewährte Strategien setzen`,
      type: 'inspirations' as const,
      subtitle: `Eine bisher ungeschlagene Performance: Der “All-time-Winner” im Vergleich mit allen Portfolios.\n\nDamit entwickelt sich das Vermögen besser, als die aller anderen Robo-Advisor im Echtgeld-Test!`,
      sources: ['https://www.brokervergleich.de/robo-advisor/echtgeld-test#echtgeldtest-t2'],
      volatilityYTY: 0.0795,
      composition: [
        {
          id: 'globalStocksMsciEuro',
          share: 0.5,
        },
        {
          id: 'europeanBondsBarclays',
          share: 0.5,
        },
      ],
    },
    {
      id: 'insp-cheap' as const,
      name: 'Das Preiswert-Depot',
      nameInspirationVariants: 'Das Preiswert-Depot',
      description: `Das Portfolio mit dem besten Preis-Wert Verhältnis und einer hohen Dividendenrendite.\n\nKunden, die mehr Wert für ihren Einsatz rausholen wollen.`,
      type: 'inspirations' as const,
      subtitle: `Preiswert bedeutet, Aktien mit einem niedrigeren Kurs-Gewinn-Verhältnis (KGV) zu kaufen und mit einer höheren Dividendenrendite.\n\nStatt in den MSCI World mit einer hohen Bewertung gemessen am KGV von 18 und einer Dividenden-Rendite von 2,0% zu investieren, wird in den MSCI World Value investiert, mit einem KGV von 14 und einer Dividenden-Rendite von 3,0% (Basis geschätzte Gewinne 2022, Quelle: Bloomberg)`,
      sources: [
        'https://www.handelsblatt.com/finanzen/indexfonds-value-indizes-als-neue-favoriten-wie-anleger-ihre-etf-strategie-nun-anpassen-koennen/28089660.html',
      ],
      volatilityYTY: 0.07,
      composition: [
        {
          id: 'globalStocksMsciValue',
          share: 0.5,
        },
        {
          id: 'overnightMoney',
          share: 0.5,
        },
      ],
    },
    {
      id: 'insp-inflation-winner' as const,
      name: 'Der Inflationsgewinner',
      nameInspirationVariants: 'Den Inflationsgewinner',
      description: `Weil Investitionen in Sachwerte grundsätzlich  von der Inflation profitieren.\n\nKunden, die von steigender Inflation in ihrem Vermögen profitieren wollen.`,
      type: 'inspirations' as const,

      subtitle: `Das Depot spiegelt die Performance des weltweiten Aktienmarkts und stabilisiert sie mit Wohnimmobilien in Deutschland, die Inflationsschutz und den Vorteil eines passiven Einkommens bieten.`,
      sources: [
        'https://www.brokervergleich.de/robo-advisor/echtgeld-test#echtgeldtest-t2',
        // 'https://www.private-banking-magazin.de/-exit-bei-offenen-fonds-1399478470/',
      ],
      volatilityYTY: 0.0795,
      composition: [
        {
          id: 'globalStocksMsciEuro',
          share: 0.5,
        },
        {
          id: 'materialAssetWertgrund',
          share: 0.5,
        },
      ],
    },
  ],
}