import { Theme, Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

const BackdropStyles = { color: '#fff', zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }
export type CBackdropProps = { open: boolean }

export const CBackdrop = (props: CBackdropProps) => {
  const { open } = props
  return (
    <Backdrop sx={BackdropStyles} open={open}>
      <CircularProgress color="primary" />
    </Backdrop>
  )
}
