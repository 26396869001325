import React from 'react'
import { Typography, Divider } from '@mui/material'
import { InvestmentProductType, PortfolioPositionType } from '../../PORTFOLIO_CONFIG'
import { formatGermanNumber } from '../../utils/utils'
import { useAppSelector } from '../../redux/hooks'
import { selectNavigation } from '../../redux/reducers/navigation'

export type PortfolioResultAssetProps = {
  dividerColor?: string
  dividerWidth?: string
  product: (InvestmentProductType & PortfolioPositionType) | undefined
}
export const PortfolioResultAsset = (props: PortfolioResultAssetProps) => {
  const { dividerColor, dividerWidth, product } = props
  const store = useAppSelector(selectNavigation)
  const oneTimeInvestmentAmount = (store?.one_time_invest_amount || 0) * (product?.share || 0)
  const monthlyInvestmentAmount = (store?.monthly_invest_amount || 0) * (product?.share || 0)
  const primaryAssetReturns = product?.years?.slice?.(-10)
  const primaryAssetOneTimeInvestReturn =
    oneTimeInvestmentAmount * (primaryAssetReturns?.reduce?.((acc, cur) => acc * (1 + cur.returnYty), 1) || 1)
  const primaryAssetOneTimeInvestPercentReturn =
    primaryAssetReturns?.reduce?.((acc, cur) => acc * (1 + cur.returnYty), 1) || 1
  const primaryAssetMonthlyInvestReturn = !monthlyInvestmentAmount
    ? 0
    : primaryAssetReturns?.reduce?.(
        (acc, cur) =>
          acc * (1 + cur.returnYty) +
          monthlyInvestmentAmount * 12 +
          ((monthlyInvestmentAmount * 12) / 6) * cur.returnYty,
        0
      ) || 0

  const startYear = product?.years?.at?.(-10)?.year
  const title = `Der ${product?.typeLabel}anteil (${(product?.share || 0) * 100}%)`
  // const performane10y = Math.floor((((product?.returnYty || 0) + 1) ** 10 - 1) * 100)
  const performane10y = Math.floor((primaryAssetOneTimeInvestPercentReturn - 1) * 100)
  const performance10yLabel = `+ ${performane10y}% Rendite des Benchmarks seit ${startYear}`
  // const absPerformance10y = Math.round(oneTimeInvestmentAmount * ((product?.returnYty || 0) + 1) ** 10)

  const oneTimeIvestmentLanel = oneTimeInvestmentAmount
    ? `Eine Einmal-Investiton von ${formatGermanNumber(oneTimeInvestmentAmount)},- Euro in ${startYear}`
    : ''

  const monthlyPerformanceLabel =
    monthlyInvestmentAmount && !oneTimeInvestmentAmount
      ? ` Monatliche Investitionen von ${formatGermanNumber(
          monthlyInvestmentAmount
        )}€ über 10 Jahre beginnend in ${startYear} (über die Laufzeit ${formatGermanNumber(
          monthlyInvestmentAmount * 12 * 10
        )}€)`
      : monthlyInvestmentAmount
      ? ` und monatliche Investitionen von ${formatGermanNumber(
          monthlyInvestmentAmount
        )}€ über 10 Jahre (über die Laufzeit ${formatGermanNumber(monthlyInvestmentAmount * 12 * 10)}€)`
      : ''
  const absPerformanceLabel = (
    <>
      {`${oneTimeIvestmentLanel}${monthlyPerformanceLabel} ${
        monthlyPerformanceLabel ? 'sind' : 'ist'
      } heute ${formatGermanNumber(
        Math.round(primaryAssetOneTimeInvestReturn + primaryAssetMonthlyInvestReturn)
      )},- Euro wert.`}
      <br />
      <br />
      {`Das Risiko, gemessen an der durchschnittlichen Schwankungsbreite, lag bei unter ${formatGermanNumber(
        Math.ceil((product?.volatilityYTY || 0) * 100 * 100) / 100
      )}% p.a.`}
    </>
  )

  return (
    <>
      <Typography fontWeight={700}>{title}</Typography>
      <Divider sx={{ borderColor: dividerColor ?? '#C4C4C4', pt: '12px', borderBottomWidth: dividerWidth ?? '1px' }} />
      <Typography paddingTop={'12px'}>{performance10yLabel}</Typography>
      <Typography paddingTop={'12px'} color="#8A95AD">
        {absPerformanceLabel}
      </Typography>
    </>
  )
}
