import React from 'react'
import { Box, Typography, Divider, useMediaQuery, useTheme, TypographyProps } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CButton } from '../components/buttons/Button'
import { LinkButton } from '../components/buttons/LinkButton'
import { scrollToTop } from '../utils/utils'

export const Home = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const handleClickNext = React.useCallback(() => {
    navigate('/objective')
    scrollToTop()
  }, [navigate])

  const isMinXlWidth = useMediaQuery(theme.breakpoints.up('xl'))
  const injectSpecificDesktopSubtitleFont: TypographyProps = isMinXlWidth
    ? { fontSize: '20px !important', lineHeight: '26px !important', textAlign: 'center', paddingTop: '53px' }
    : { paddingTop: "11px" }
  const injectCenteredTypography: TypographyProps = isMinXlWidth ? { textAlign: 'center' } : {}

  return (
    <Box sx={{ pt: '14px' }}>
      {/* Typography */}
      <Typography variant="h1">Der Robo-Advisor</Typography>
      <Typography fontWeight={700} {...injectSpecificDesktopSubtitleFont}>
        Kostenlos und neutral.
      </Typography>
      <Typography paddingTop={isMinXlWidth ? '15px' : '36px'} {...injectCenteredTypography}>
        Aktienrente starten!
      </Typography>
      <Divider sx={{ borderColor: '#8A95AD', pt: isMinXlWidth ? '53px' : '11px' }} />
      <Typography paddingTop={isMinXlWidth ? '53px' : '12px'} {...injectCenteredTypography}>
        Wählen Sie aus den Modellportfolios eine passende Aktienquote.
      </Typography>
      <CButton sx={{ mt: isMinXlWidth ? '40px' : '97px', width: '100%' }} onClick={handleClickNext}>
        Start
      </CButton>
      <Typography paddingTop={'69px'}>
        Sie sind sich unsicher, welche grundsätzliche Herangehensweise bei der Geldanlage für Sie richtig ist?
      </Typography>

      <LinkButton pt={'9px'} href="https://invest-o-mat.villiam.eu/">
        Zum Invest-O-Mat
      </LinkButton>
    </Box>
  )
}
