import React from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import { ShareWithFriends } from './content/ShareWithFriends'

const paperStyles = {
  sx: { m: 1 },
}

export type ShareDialogProps = DialogProps
export const ShareDialog = (props: ShareDialogProps) => {
  const { open, onClose, ...rest } = props

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="share-dialog-title"
      aria-describedby="share-dialog-description"
      PaperProps={paperStyles}
      {...rest}
    >
      <DialogTitle id="alert-dialog-title" variant="h3">
        {'Gefällt Ihnen der Robo-Advisor?'}
      </DialogTitle>
      <DialogContent>
        <ShareWithFriends />
      </DialogContent>
    </Dialog>
  )
}
