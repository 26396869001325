import React from 'react'
import { Box, Typography, Stack, Divider, Link, useTheme, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LinkButton } from '../components/buttons/LinkButton'
import { ProgressBar } from '../components/ProgressBar'
import { CButton } from '../components/buttons/Button'
import { PieChart } from '../components/PieChart'
import { LineChart } from '../components/LineChart'
import { useAppSelector } from '../redux/hooks'
import { selectNavigation } from '../redux/reducers/navigation'
import {
  AnonymousInvestmentProductType,
  CategoryInvestmentProductType,
  ComposedPortfolio,
  InvestmentProductType,
  PortfolioType,
  PORTFOLIO_DATA,
  SpecificInvestmentProductType,
  SpecificPortfolio,
} from '../PORTFOLIO_CONFIG'
import { formatGermanNumber, parseStringNewLines, scrollToTop } from '../utils/utils'
import { RiskAdvisoryHistory } from '../components/content/RiskAdvisoryHistory'
import { ShareWithFriends } from '../components/content/ShareWithFriends'
import { PortfolioResultAsset } from '../components/content/PortfolioResultAsset'
import { PortfolioResultAssetCard } from '../components/content/PortfolioResultAssetCard'
import { useUrlParams } from '../utils/navigation'

export const getPieChartData = (shares: number[]) => ({
  // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      // label: '# of Votes',
      data: shares,
      backgroundColor: ['#0171E3', '#00D1FF'],
      borderColor: ['#fff', '#fff'],
      // borderWidth: 5,
    },
  ],
})

const labels = ['', 'Start', '', '', '', '', '5 Jahre', '', '', '', '', '10 Jahre']

export const makeLineChartData = (data1: number[], benchmarkData: number[], data2?: number[]) => ({
  labels,
  datasets: [
    {
      label: 'Projizierter Marktwert der Portfolio-Strategie',
      data: [null, ...data1],
      borderColor: '#2860F6',
      backgroundColor: '#2860F6',
    },
    ...(data2
      ? [
          {
            label: 'Anschaffungswerte der Portfolio-Strategie',
            data: [null, ...data2],
            borderColor: '#00D1FF',
            backgroundColor: '#00D1FF',
          },
        ]
      : []),
    {
      label: 'Benchmark Durchschnitt der Robo-Advisor',
      data: [null, ...benchmarkData],
      borderColor: 'silver',
      backgroundColor: 'silver',
    },
  ],
  //   options: {
  //     plugins: {
  //       legend: {
  //         labels: {
  //           // This more specific font property overrides the global property
  //           font: {
  //             size: 14,
  //           },
  //         },
  //       },
  //     },
  //   },
})

export const Result = () => {
  const navigate = useNavigate()
  useUrlParams()
  const store = useAppSelector(selectNavigation)
  const theme = useTheme()
  const portfolio = PORTFOLIO_DATA.portfolios.find((pf) => pf.id === store.strategy_variant)
  const portfolioDataset: ComposedPortfolio = {
    ...(portfolio as PortfolioType),
    composition:
      portfolio?.composition?.map((position) => ({
        ...position,
        ...(PORTFOLIO_DATA.investmentProducts.find((prod) => prod.id === position.id) as InvestmentProductType),
      })) ?? [],
  }
  const pieChartData = React.useMemo(
    () => getPieChartData(portfolioDataset?.composition?.map?.((position) => position.share) || []),
    [portfolioDataset?.composition]
  )
  const handleNavigateStepGuide = React.useCallback(() => {
    const urlParams = `?ziel=${store?.objective}&cat=${store?.strategy}&variant=${store.strategy_variant}&einmalig=${store.one_time_invest_amount}&monatlich=${store.monthly_invest_amount}`
    navigate(`/steps/${urlParams}`)
    scrollToTop()
  }, [
    navigate,
    store.monthly_invest_amount,
    store.one_time_invest_amount,
    store?.objective,
    store?.strategy,
    store.strategy_variant,
  ])
  const handleNavigateEtfCriteria = React.useCallback(() => {
    navigate('/etf-criteria')
    scrollToTop()
  }, [navigate])

  const isMinXlWidth = useMediaQuery(theme.breakpoints.up('xl'))
  const primaryAsset = portfolioDataset?.composition?.[0]
  const secondaryAsset = portfolioDataset?.composition?.[1]

  const oneTimeInvestmentAmount = store?.one_time_invest_amount || 0
  const monthlyInvestmentAmount = store?.monthly_invest_amount || 0
  const primaryAssetReturns = primaryAsset?.years?.slice?.(-10)
  if (primaryAssetReturns?.length !== 10)
    console.warn(
      `WARNING - the data input does not contain 10 years of return history but: ${primaryAssetReturns?.length}`
    )
  const primaryAssetReturnsAcc = primaryAssetReturns?.reduce<number[]>(
    (acc, cur) =>
      !acc?.length ? [(cur?.returnYty || 0) + 1] : [...acc, (acc?.at?.(-1) || 1) * ((cur?.returnYty || 0) + 1)],
    []
  )
  const secondaryAssetReturns = secondaryAsset?.years?.slice?.(-10)
  if (secondaryAssetReturns?.length !== 10)
    console.warn(
      `WARNING - the data input does not contain 10 years of return history but: ${secondaryAssetReturns?.length}`
    )
  const secondaryAssetReturnsAcc = secondaryAssetReturns?.reduce<number[]>(
    (acc, cur) =>
      !acc?.length ? [(cur?.returnYty || 0) + 1] : [...acc, (acc?.at?.(-1) || 1) * ((cur?.returnYty || 0) + 1)],
    []
  )

  const totalAssetReturns = primaryAssetReturnsAcc?.map(
    (primAccReturn, pIdx) =>
      (primaryAsset?.share || 0) * primAccReturn +
      (secondaryAsset?.share || 0) * (secondaryAssetReturnsAcc?.[pIdx] || 0)
  )

  const totalReturnPa = (totalAssetReturns?.at(-1) || 0) ** (1 / 10) - 1
  // const totalAssetTotalReturns = totalAssetReturns?.map?.((tr) => oneTimeInvestmentAmount * tr)
  const totalMonthly = [0]
  for (let i = 0; i < 10; i += 1) {
    totalMonthly.push(
      (totalMonthly?.at?.(-1) || 0) * (1 + totalReturnPa) +
        monthlyInvestmentAmount * 12 +
        ((monthlyInvestmentAmount * 12) / 6) * totalReturnPa
    )
  }

  const totalAssetTotalReturns = [
    oneTimeInvestmentAmount + 0,
    ...(totalAssetReturns?.map?.((tr, tIdx) =>
      Math.round(oneTimeInvestmentAmount * (1 + totalReturnPa) ** (tIdx + 1) + (totalMonthly?.[tIdx + 1] ?? 0))
    ) ?? []),
  ]

  const benchmarkAvgReturnYty = 1.303 ** (1 / 6) - 1
  const totalMonthlyBenchmark = [0]
  for (let i = 0; i < 10; i += 1) {
    totalMonthlyBenchmark.push(
      (totalMonthlyBenchmark?.at?.(-1) || 0) * (1 + benchmarkAvgReturnYty) +
        monthlyInvestmentAmount * 12 +
        ((monthlyInvestmentAmount * 12) / 6) * benchmarkAvgReturnYty
    )
  }
  const totalAssetBenchmarkTotalReturns = [
    oneTimeInvestmentAmount + 0,
    ...(totalAssetReturns?.map?.((tr, tIdx) =>
      Math.round(
        oneTimeInvestmentAmount * (1 + benchmarkAvgReturnYty) ** (tIdx + 1) + (totalMonthlyBenchmark?.[tIdx + 1] ?? 0)
      )
    ) ?? []),
  ]

  const buyValues = new Array(11)
    .fill(0)
    .map((x, idx) => Math.round(oneTimeInvestmentAmount + idx * (12 * monthlyInvestmentAmount)))
  const disableBuyValuesChart = !monthlyInvestmentAmount

  // const primaryReturnPa = (primaryAssetReturnsAcc?.at(-1) || 0) ** (1 / 10) - 1
  // const secondarayReturnPa = (secondaryAssetReturnsAcc?.at(-1) || 0) ** (1 / 10) - 1
  // console.log(primaryAssetReturnsAcc, secondaryAssetReturnsAcc, totalReturnPa, primaryReturnPa, secondarayReturnPa)

  return (
    <Box>
      {/* Overview */}
      <ProgressBar progress={100} label="Das Ergebnis" pt={isMinXlWidth ? '32px' : 0} />
      <Box
        sx={{
          width: isMinXlWidth ? '775px' : '100%',
          position: 'relative',
          left: isMinXlWidth ? '-200px' : '0px',
        }}
      >
        <Typography
          variant="h2"
          paddingTop={isMinXlWidth ? '66px' : '26px'}
          textAlign={isMinXlWidth ? 'center' : 'left'}
          sx={{ overflowWrap: 'break-word' }}
        >
          {store.strategy === 'professionals'
            ? `Das Ergebnisportfolio:`
            : store.strategy === 'inspirations'
            ? portfolioDataset.name
            : null}
        </Typography>
      </Box>
      <Typography paddingTop={'26px'} textAlign={isMinXlWidth ? 'center' : 'left'}>
        {store.strategy === 'professionals'
          ? `Ein Anlagemix aus Aktien und Anleihen.`
          : parseStringNewLines((portfolioDataset as unknown as SpecificPortfolio)?.subtitle ?? '')}
      </Typography>
      {store.strategy === 'inspirations' &&
        (portfolioDataset as unknown as SpecificPortfolio)?.sources?.map?.((src, sIdx) => (
          <LinkButton paddingTop={!sIdx ? '26px' : '8px'} href={src} key={sIdx}>
            Quelle
          </LinkButton>
        ))}
      <Typography
        paddingTop={store.strategy === 'professionals' ? '68px' : '32px'}
        color="#8A95AD"
        textAlign={isMinXlWidth ? 'center' : 'left'}
      >
        {store.strategy === 'professionals' ? `Musterportfolio kopieren?` : `Strategie direkt umsetzen?`}
      </Typography>
      <CButton type="secondary" sx={{ mt: '12px', width: '100%' }} onClick={handleNavigateStepGuide}>
        Zur Step-by-Step Anleitung
      </CButton>
      <Box
        sx={{
          position: 'relative',
          left: isMinXlWidth ? '-412px' : 0,
          width: isMinXlWidth ? '1200px' : 'auto',
          pr: isMinXlWidth ? '96px' : 0,
          pl: isMinXlWidth ? '96px' : 0,
        }}
      >
        {isMinXlWidth ? (
          <Box border="1px solid #C4C4C4" mt="70px" pb="32px">
            <Box display="grid" gridTemplateColumns="auto 250px auto" pr="32px" pl="32px" gap="32px">
              <Box pt="60px">
                <Typography fontSize="24px" lineHeight="31px" fontWeight={700} variant="customSize">
                  Zusammensetzung
                </Typography>
                <Stack direction="row" gap="25px" pt="40px">
                  <Box width="15px" height="62px" bgcolor="primary.main" borderRadius="100px" />
                  <Box>
                    <Typography fontSize="20px" lineHeight="26px" variant="customSize" fontWeight={700}>
                      {`${primaryAsset?.typeLabel}`}
                    </Typography>
                    <Typography
                      fontSize="20px"
                      lineHeight="26px"
                      paddingTop="8px"
                      variant="customSize"
                      fontWeight={700}
                    >
                      {`${Math.round((primaryAsset?.share || 0) * 100)}%`}
                    </Typography>
                  </Box>
                  <Box width="15px" height="62px" bgcolor="secondary.main" borderRadius="100px" />
                  <Box>
                    <Typography fontSize="20px" lineHeight="26px" variant="customSize" fontWeight={700}>
                      {`${secondaryAsset?.typeLabel}`}
                    </Typography>
                    <Typography
                      fontSize="20px"
                      lineHeight="26px"
                      paddingTop="8px"
                      variant="customSize"
                      fontWeight={700}
                    >
                      {`${Math.round((secondaryAsset?.share || 0) * 100)}%`}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box height="250px" mt="16px" mb="16px">
                <PieChart data={pieChartData} />
              </Box>
              <Box pt="60px">
                <Typography fontSize="24px" lineHeight="31px" fontWeight={700} variant="customSize">
                  Performance
                </Typography>
                <Box display="grid" gridTemplateColumns="auto max-content" gap="16px 0" pt="16px">
                  <Typography>Gesamtrendite:</Typography>
                  <Typography>{`${((totalReturnPa || 0) * 100).toFixed(2)}% p.a.`}</Typography>
                  <Typography>Risiko (Volatilität):</Typography>
                  <Typography>{`unter ${((portfolioDataset.volatilityYTY || 0) * 100).toFixed(2)}% p.a.`}</Typography>
                </Box>
                <Typography paddingTop={'35px'} color="#8A95AD">
                  Das Risiko errechnen wir aus der durchschnittlichen Schwankungsbreite der Rendite.
                </Typography>
              </Box>
              <Box pt="16px" sx={{ gridColumn: '1 / 3' }}>
                <Typography fontSize="24px" lineHeight="31px" fontWeight={700} variant="customSize">
                  Die Portfolio-Strategie Performance
                </Typography>

                <Box pt="32px">
                  <LineChart
                    data={makeLineChartData(
                      totalAssetTotalReturns ?? [],
                      totalAssetBenchmarkTotalReturns ?? [],
                      !disableBuyValuesChart ? buyValues : undefined
                    )}
                  />
                </Box>
              </Box>
              <Box alignSelf="center">
                <RiskAdvisoryHistory pt="16px" />
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Divider sx={{ borderColor: '#C4C4C4', pt: '32px' }} />
            {/* Composition */}
            <Stack direction="row" justifyContent="space-between" pt="32px">
              <Box>
                <Typography fontWeight={700}>Zusammensetzung</Typography>
                {portfolioDataset?.composition?.map((position, pIdx) => (
                  <Typography
                    paddingTop={!pIdx ? '22px' : '12px'}
                    color="primary"
                    sx={{ textDecoration: 'underline' }}
                    key={pIdx}
                  >
                    {`${Math.round((position?.share || 0) * 100)}% ${position.typeLabel}`}
                  </Typography>
                ))}
              </Box>
              <Box sx={{ position: 'relative', width: 100 }}>
                <PieChart data={pieChartData} />
              </Box>
            </Stack>
            <Divider sx={{ borderColor: '#C4C4C4', pt: '55px' }} />
            {/* Performance */}
            <Typography fontWeight={700} paddingTop="23px">
              Performance
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="max-content auto"
              sx={{ justifyContent: 'space-between', gap: '7px 0px', pt: '22px' }}
            >
              <Typography>Gesamtrendite:</Typography>
              <Typography fontWeight={700} textAlign="right">{`${((totalReturnPa || 0) * 100).toFixed(
                2
              )}% p.a.`}</Typography>
              <Typography>Risiko (Volatilität):</Typography>
              <Typography fontWeight={700} textAlign="right">{`unter ${(
                (portfolioDataset.volatilityYTY || 0) * 100
              ).toFixed(2)}% p.a.`}</Typography>
            </Box>
            <Typography paddingTop={'35px'} color="#8A95AD">
              Das Risiko errechnen wir aus der durchschnittlichen Schwankungsbreite der Rendite.
            </Typography>
            <RiskAdvisoryHistory pt="63px" />
            <Box width={280} maxWidth="100%" mr="auto" ml="auto" pt={'62px'}>
              <LineChart
                data={makeLineChartData(
                  totalAssetTotalReturns ?? [],
                  totalAssetBenchmarkTotalReturns ?? [],
                  !disableBuyValuesChart ? buyValues : undefined
                )}
                height={300}
              />
            </Box>
          </>
        )}
        <Stack
          direction={isMinXlWidth ? 'row' : 'column'}
          justifyContent={isMinXlWidth ? 'space-between' : 'flex-start'}
        >
          {/* Composition-Details 1 */}
          <Box pt={'57px'} maxWidth="375px">
            <PortfolioResultAsset
              product={primaryAsset}
              dividerColor={isMinXlWidth ? theme.palette.primary.main : undefined}
              dividerWidth={isMinXlWidth ? '3px' : '1px'}
            />
            {(primaryAsset as CategoryInvestmentProductType)?.useAltText ? (
              <Box pt="12px">
                <Box>{(primaryAsset as CategoryInvestmentProductType)?.altText}</Box>
                <Link
                  paddingTop="12px"
                  href={(primaryAsset as CategoryInvestmentProductType)?.altLinkHref}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {(primaryAsset as CategoryInvestmentProductType)?.altLinkLabel}
                </Link>
              </Box>
            ) : portfolioDataset.type === 'professionals' ? (
              <Box mt="21px">
                <PortfolioResultAssetCard
                  header={`100% ${primaryAsset?.category}`}
                  item={(primaryAsset as AnonymousInvestmentProductType)?.nameAnonymized}
                  itemLinkHref={(primaryAsset as AnonymousInvestmentProductType)?.furtherInfoLink ?? '#'}
                  itemDescription={parseStringNewLines(
                    (primaryAsset as AnonymousInvestmentProductType)?.descriptionAnonymized ?? ''
                  )}
                  regulationNotice={(primaryAsset as AnonymousInvestmentProductType)?.regulationNotice}
                  buttonLabel={(primaryAsset as AnonymousInvestmentProductType)?.buttonLabelAnonymized ?? ''}
                  cardImage={(primaryAsset as SpecificInvestmentProductType)?.cardImage}
                />
              </Box>
            ) : (
              <Box mt="21px">
                <PortfolioResultAssetCard
                  header={
                    store?.one_time_invest_amount
                      ? `${formatGermanNumber((store?.one_time_invest_amount || 0) * (primaryAsset?.share || 0))}€ ${
                          primaryAsset?.category
                        }`
                      : `${primaryAsset?.category}`
                  }
                  item={(primaryAsset as SpecificInvestmentProductType)?.name}
                  itemLinkHref={(primaryAsset as SpecificInvestmentProductType)?.productLink ?? '#'}
                  itemDescription={parseStringNewLines(
                    (primaryAsset as SpecificInvestmentProductType)?.description ?? ''
                  )}
                  buttonLabel={(primaryAsset as SpecificInvestmentProductType)?.buttonLabel ?? ''}
                  cardImage={(primaryAsset as SpecificInvestmentProductType)?.cardImage}
                  specificButtonLink={(primaryAsset as SpecificInvestmentProductType)?.buttonToLink}
                />
              </Box>
            )}
            {primaryAsset?.typeLabel === 'Aktien' && (
              <>
                <Typography paddingTop={'26px'}>
                  Bei der Auswahl der Aktieninvestition ist es wichtig, einige Faktoren zu beachten.
                  <br />
                  <br /> Sie möchten mehr zu den Kriterien erfahren, nach denen wir unsere ETF’s auswählen?
                </Typography>
                <LinkButton pt={'28px'} onClick={handleNavigateEtfCriteria}>
                  Unsere Auswahlkriterien
                </LinkButton>
              </>
            )}
          </Box>
          {/* Composition-Details 2 */}
          <Box pt={'57px'} maxWidth="375px">
            <PortfolioResultAsset
              product={secondaryAsset}
              dividerColor={isMinXlWidth ? theme.palette.secondary.main : undefined}
              dividerWidth={isMinXlWidth ? '3px' : '1px'}
            />
            {(secondaryAsset as CategoryInvestmentProductType)?.useAltText ? (
              <Box pt="12px">
                <Box>{parseStringNewLines((secondaryAsset as CategoryInvestmentProductType)?.altText ?? '')}</Box>
                <Box paddingTop="12px">
                  <Link
                    href={(secondaryAsset as CategoryInvestmentProductType)?.altLinkHref}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {(secondaryAsset as CategoryInvestmentProductType)?.altLinkLabel}
                  </Link>
                </Box>
              </Box>
            ) : portfolioDataset.type === 'professionals' ? (
              <Box mt="21px">
                <PortfolioResultAssetCard
                  header={`100% ${(secondaryAsset as AnonymousInvestmentProductType)?.category}`}
                  item={(secondaryAsset as AnonymousInvestmentProductType)?.nameAnonymized}
                  itemLinkHref={(secondaryAsset as AnonymousInvestmentProductType)?.furtherInfoLink ?? '#'}
                  itemDescription={parseStringNewLines(
                    (secondaryAsset as AnonymousInvestmentProductType)?.descriptionAnonymized ?? ''
                  )}
                  regulationNotice={(secondaryAsset as AnonymousInvestmentProductType)?.regulationNotice}
                  buttonLabel={(secondaryAsset as AnonymousInvestmentProductType)?.buttonLabelAnonymized ?? ''}
                  cardImage={(secondaryAsset as SpecificInvestmentProductType)?.cardImage}
                />
              </Box>
            ) : (
              <Box mt="21px">
                <PortfolioResultAssetCard
                  header={
                    store?.one_time_invest_amount
                      ? `${formatGermanNumber((store?.one_time_invest_amount || 0) * (secondaryAsset?.share || 0))}€ ${
                          secondaryAsset?.category
                        }`
                      : `${secondaryAsset?.category}`
                  }
                  item={(secondaryAsset as SpecificInvestmentProductType)?.name}
                  itemLinkHref={(secondaryAsset as SpecificInvestmentProductType)?.productLink ?? '#'}
                  itemDescription={parseStringNewLines(
                    (secondaryAsset as SpecificInvestmentProductType)?.description ?? ''
                  )}
                  buttonLabel={(secondaryAsset as SpecificInvestmentProductType)?.buttonLabel ?? ''}
                  cardImage={(secondaryAsset as SpecificInvestmentProductType)?.cardImage}
                  specificButtonLink={(secondaryAsset as SpecificInvestmentProductType)?.buttonToLink}
                />
              </Box>
            )}
            {secondaryAsset?.typeLabel === 'Aktien' && (
              <>
                <Typography paddingTop={'26px'}>
                  Bei der Auswahl der Aktieninvestition ist es wichtig, einige Faktoren zu beachten.
                  <br />
                  <br /> Sie möchten mehr zu den Kriterien erfahren, nach denen wir unsere ETF’s auswählen?
                </Typography>
                <LinkButton pt={'28px'} onClick={handleNavigateEtfCriteria}>
                  Unsere Auswahlkriterien
                </LinkButton>
              </>
            )}
          </Box>
        </Stack>

        {/* Realization Guide */}
        <Box display={isMinXlWidth ? 'none' : 'block'}>
          <Typography paddingTop={'40px'} fontWeight={700}>
            Wie kann ich die Strategie umsetzen?
          </Typography>
          <CButton
            sx={{ mt: '25px', width: '100%', fontSize: '18px', lineHeight: '21px' }}
            onClick={handleNavigateStepGuide}
          >
            Zu Ihrer Step-by-Step Anleitung
          </CButton>
        </Box>
        <Box pt="126px">
          {isMinXlWidth ? (
            <Typography fontWeight={700} textAlign="center" pb="48px">
              Den Robo-Advisor weiterempfehlen!
            </Typography>
          ) : (
            <ShareWithFriends />
          )}
        </Box>
      </Box>
    </Box>
  )
}
