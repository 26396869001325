import React from 'react'
import { alpha, IconButton, Link as MuiLink, Stack, StackProps, Typography } from '@mui/material'
import { mdiArrowRight } from '@mdi/js'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom'

export type LinkButtonProps = StackProps & {
  children?: React.ReactNode
  href?: string
  to?: string
}

export const LinkButton = (props: LinkButtonProps) => {
  const { children, href, to, ...rest } = props

  const handleNavigate = React.useCallback(() => {
    if (!href) return
    window.open(href, '_blank', 'noopener,noreferrer')
  }, [href])

  return (
    <Stack direction="row" justifyContent="space-between" {...rest}>
      {to ? (
        <Link to={to} style={{ textDecoration: 'none' }}>
          <Typography
            sx={{
              textDecoration: 'underline',
              color: 'primary.main',
              textDecorationColor: (theme) => alpha(theme.palette.primary.main, 0.4),
              '&: hover': { textDecorationColor: (theme) => theme.palette.primary.main },
            }}
          >
            {children}
          </Typography>
        </Link>
      ) : (
        <MuiLink href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </MuiLink>
      )}
      <IconButton sx={{ p: 0, color: 'primary.main' }} onClick={handleNavigate}>
        <Icon path={mdiArrowRight} size={1} />
      </IconButton>
    </Stack>
  )
}
