import React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { Typography, BoxProps, useMediaQuery, useTheme } from '@mui/material'

export type ProgressbarProps = BoxProps & {
  progress: number
  label?: string
}

export const ProgressBar = (props: ProgressbarProps) => {
  const { progress, label, ...rest } = props
  const theme = useTheme()
  const isMinXlWidth = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Box
      sx={{
        width: isMinXlWidth ? '775px' : '100%',
        color: '#1A2C58',
        position: 'relative',
        left: isMinXlWidth ? '-200px' : '0px',
      }}
      {...rest}
    >
      <Typography variant="caption" component="div" textAlign={isMinXlWidth ? 'center' : 'left'}>
        {label}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        color="inherit"
        sx={{ borderRadius: '4px', background: '#E9E9E9', mt: '12px', '&: before': { opacity: 0 } }}
      />
    </Box>
  )
}
