import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LinkButton } from '../components/buttons/LinkButton'
import { CButton } from '../components/buttons/Button'
import { useUrlParams } from '../utils/navigation'

export const EtfCriteria = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMinXlWidth = useMediaQuery(theme.breakpoints.up('xl'))
  useUrlParams()

  const handleNavigateBack = React.useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <Box>
      <Typography variant="h3" paddingTop={'0px'}>
        Unsere ETF-Kriterien
      </Typography>
      <Typography paddingTop={'31px'}>
        Bei der Auswahl von ETF ist es wichtig, einige Faktoren zu beachten. Wir wählen nur ETF’s, die wir nach folgende
        Kriterien geprüft haben:
      </Typography>
      <br />
      <Box component="ul" color="#152C5B" pt="0x" mt="0px" mb="0px" sx={{ paddingInlineStart: '24px' }}>
        <Box component="li">
          <Typography>Hohe Diversifizierung</Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>Geringe Kosten</Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>Mindestvolumen Assets under Management</Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>Tracking-Error, d.h. Abweichung vom zugrunde liegenden Index</Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>
            Spread, d.h Differenz zwischen Kauf- und Verkaufskurs und damit Kaufkosten und deren Verlauf
          </Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>Anbieter, Konzernzugehörigkeit, Marktverhalten</Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>Aufbau: physisch oder synthetisch</Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>Ausschüttend oder thesaurierend</Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>Kursverlauf und Spreads in Stresssituationen</Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>Abwicklung von großen Volumen</Typography>
        </Box>
        <Box component="li" pt="4px">
          <Typography>Empfehlungen, Ratings von Analysehäusern und Verbraucherschutzportalen</Typography>
        </Box>
      </Box>
      <Typography paddingTop={'34px'} fontWeight={700}>
        Literatur
      </Typography>
      <LinkButton
        pt={'18px'}
        href="https://www.handelsblatt.com/finanzen/indexfonds-diese-goldenen-regeln-fuer-das-etf-portfolio-sollten-sie-beherzigen/28168128.html"
      >
        Handelsblatt: Die goldenen Regeln für das ETF-Portfolio
      </LinkButton>
      {!isMinXlWidth ? (
        <CButton type="secondary" sx={{ width: 141, mt: '66px' }} onClick={handleNavigateBack}>
          zurück
        </CButton>
      ) : (
        <Box pt="32px" />
      )}
    </Box>
  )
}
