import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import { ProgressBar } from '../components/ProgressBar'
import { LinkButton } from '../components/buttons/LinkButton'
import { ShareWithFriends } from '../components/content/ShareWithFriends'
import {
  AnonymousInvestmentProductType,
  CategoryInvestmentProductType,
  InvestmentProductType,
  PortfolioType,
  PORTFOLIO_DATA,
  SpecificInvestmentProductType,
} from '../PORTFOLIO_CONFIG'
import { useAppSelector } from '../redux/hooks'
import { selectNavigation } from '../redux/reducers/navigation'
import { formatGermanNumber, parseStringNewLines } from '../utils/utils'
import { PortfolioResultAssetCard } from '../components/content/PortfolioResultAssetCard'
import { useUrlParams } from '../utils/navigation'

export type RealizationStepProps = {
  header?: React.ReactNode
  primaryText?: React.ReactNode
  secondaryText?: React.ReactNode
}
export const RealizationStep = (props: RealizationStepProps) => {
  const { header, primaryText, secondaryText } = props
  return (
    <>
      {header && <Typography sx={{ textDecoration: 'underline', lineHeight: '19px' }}>{header}</Typography>}
      {primaryText && <Typography sx={{ lineHeight: '19px', fontWeight: 700, pt: '12px' }}>{primaryText}</Typography>}
      {secondaryText && <Typography sx={{ lineHeight: '19px', pt: '12px' }}>{secondaryText}</Typography>}
    </>
  )
}

export type BasicStepContent = {
  primaryText: React.ReactNode
  secondaryText: React.ReactNode
  linkLabel?: React.ReactNode
  href?: string
  to?: string
}

export type StepContent = BasicStepContent | { showCard: true }

const FIRST_STEPS: BasicStepContent[] = [
  {
    primaryText: 'Eröffnen Sie ein Depot.',
    secondaryText: 'Hier finden Sie die Bank, die am besten zu Ihrer Strategie passt.',
    linkLabel: 'Sie suchen eine günstige Depot-Bank?',
    href: 'https://www.financeads.net/tc.php?t=52482C15212339T',
  },
  {
    primaryText: 'Legen Sie einen Investmentbetrag fest und überweisen Sie diesen in Ihr Depot Verrechnungskonto.',
    secondaryText:
      'Hierbei ist es wichtig, dass Sie ihre gesamte Vermögenssituation betrachten und einen angemessenen Betrag festlegen.',
    linkLabel: 'Wie viel kann ich investieren?',
    href: 'https://www.villiam.eu/o-wissen/vermogensbilanz',
  },
]

const BUY_STOCKS_ETF_STEPS: StepContent[] = [
  {
    primaryText: 'Wählen Sie den für Sie passenden ETF',
    secondaryText:
      'Wählen Sie aus der Auswahl bei justetf.de den für Sie passenden ETF. Hierbei ist es wichtig, einige Kriterien zu beachten.',
    linkLabel: 'Welche Kriterien bei der Auswahl sind wichtig?',
    to: '/etf-criteria',
  },
  {
    primaryText: 'Platzieren Sie eine “Order” für den ETF bei Ihrer Depot-Bank',
    secondaryText: 'Verteilen Sie Ihre Anlagesumme gemäß der empfohlenen Aufteilung auf den ETF.',
  },
  { showCard: true },
]

const BUY_BONDS_GERMAN_GOV: StepContent[] = [
  {
    primaryText: 'Suchen Sie sich eine passende Bundesanleihe bei der Bundesbank',
    secondaryText: (
      <>
        Die Bundesbank bietet eine aktuelle Übersicht an Bundesanleihen.
        <br />
        <br />
        Suchen Sie eine Anleihe mit positiver Rendite, die zu Ihrem Anlagezeitraum passt.
      </>
    ),
  },
  { showCard: true },
  {
    primaryText: 'Kaufen Sie die Anleihe in Ihr Wertpapierdepot',
    secondaryText: 'Kaufen Sie die Anleihe bei Ihrer Depotbank.',
  },
]

const BUY_BONDS_EURO_BONDS: StepContent[] = [
  {
    primaryText: 'Informieren Sie sich über die Anleihen und kaufen Sie den ETF in Ihr Wertpapierdepot',
    secondaryText: 'Die genauen Daten zu dem Investment finden Sie über den untenstehenden Link.',
  },
  { showCard: true },
]
const BUY_FESTGELD: StepContent[] = [
  {
    primaryText: 'Suchen Sie sich einen passenden Festgeldanbieter bei finanztip.de',
    secondaryText:
      'Finanztip.de bietet eine aktuelle Übersicht an Festgeldanbietern. Suchen Sie den für Sie passenden Anbieter. Achten Sie auf Laufzeit und Rendite.',
    linkLabel: 'Welche Kriterien bei der Festgeldauswahl sind wichtig?',
    href: 'https://www.finanztip.de/festgeld/',
  },
  { showCard: true },
  {
    primaryText: 'Eröffnen Sie das Festgeldkonto',
    secondaryText: (
      <>
        Die einzelnen Schritte variieren pro Anbieter. <br />
        <br />
        Weitere Details finden Sie bei finanztip.de und dem jeweiligen Anbieter.
      </>
    ),
  },
]

const BUY_TAGESGELD: StepContent[] = [
  {
    primaryText: 'Suchen Sie sich einen passenden Tagesgeldanbieter bei finanztip.de',
    secondaryText:
      'Finanztip.de bietet eine aktuelle Übersicht an Tagesgeldanbietern. Suchen Sie den für Sie passenden Anbieter.',
    linkLabel: 'Welche Kriterien bei der Tagesgeldauswahl sind wichtig?',
    href: 'https://www.finanztip.de/tagesgeld/',
  },
  { showCard: true },
  {
    primaryText: 'Eröffnen Sie das Tagesgeldkonto',
    secondaryText: (
      <>
        Die einzelnen Schritte variieren pro Anbieter. <br />
        <br />
        Weitere Details finden Sie bei finanztip.de und dem jeweiligen Anbieter.
      </>
    ),
  },
]

const BUY_MAT_ASSETS_WERTGRUND: StepContent[] = [
  {
    primaryText: 'Kaufen Sie den WERTGRUND WohnSelect D in Ihr Depot',
    secondaryText: (
      <>
        Der Fonds kann börsentäglich über diverse Handelsplätze erworben werden.
        <br />
        <br />
        Als Orientierung für einen fairen Kurs gilt der von der KVG (Kapitalverwaltungsgesellschaft) veröffentlichte NAV
        (Netto-Inventarwert). <br />
        <br />
        Unsere Empfehlung ist, nur maximal 1,5% über dem NAV zu kaufen, also mit Kauflimit zu arbeiten. <br />
        <br />
        Den Kurs und NAV finden Sie zum Beispiel bei onvista.de
      </>
    ),
  },
  { showCard: true },
]

const FINAL_STEPS: BasicStepContent[] = [
  {
    primaryText: 'Investment in Ihrem virtuellen Villiam-Depot bestätigen.',
    secondaryText: (
      <>
        Wir führen für Sie ein virtuelles Depot. Dort sind Ihre Vermögenswerte ersichtlich und wir versorgen Sie mit
        aktuellen Informationen. <br /> <br /> Um Ihre Investments zu bestätigen, schreiben Sie uns eine E-Mail mit dem
        Produkt und Betrag an kontakt@villiam.eu
      </>
    ),
  },
  {
    primaryText: 'Monitoring durch Villiam genießen und weitere exklusive Anlagemöglichkeiten erhalten',
    secondaryText: (
      <>
        Indem Sie Ihr Investment bei uns bestätigen, profitieren Sie von unserer laufenden Analyse Ihrer Investments.
        Wir informieren Sie kostenlos über wichtige Änderungen und Hinweise. <br />
        <br />
        Außerdem erhalten Sie weitere, geprüfte Investment-Angebote von uns per E-Mail.
      </>
    ),
  },
]

const ADDITIONAL_FINAL_STEPS_SAVING_PLAN = (
  monthlyInvest: number,
  typeLabel1: string,
  typeLabel2: string,
  share1: number,
  share2: number
): BasicStepContent[] =>
  !monthlyInvest
    ? []
    : [
        {
          primaryText: 'Richten Sie einen Sparplan bei Ihrem Broker ein.',
          secondaryText: (
            <>
              Beauftragen Sie Ihren Broker mit einem Sparplan für Ihren gewählten Produktmix aus
              <br />
              <br />
              {`${typeLabel1} im Wert von ${formatGermanNumber(monthlyInvest * share1)}€ (${share1 * 100}%) und `}
              <br />
              {`${typeLabel2} in Wert von ${formatGermanNumber(monthlyInvest * share2)}€ (${share2 * 100}%). `}
              <br />
              <br />
              Auf diese Weise können Sie Ihren Anlageplan entspannt verfolgen und bleiben am Ball.
            </>
          ),
        },
      ]

const getAssetContent = (compositionIdx: number, portfolioDataset: any) =>
  portfolioDataset?.composition?.[compositionIdx]?.category === 'Weltweite Aktien'
    ? BUY_STOCKS_ETF_STEPS
    : portfolioDataset?.composition?.[compositionIdx]?.category === 'Festverzinsliche Euro-Anleihen'
    ? BUY_BONDS_EURO_BONDS
    : portfolioDataset?.composition?.[compositionIdx]?.nameAnonymized === 'Bundesanleihen Deutschland'
    ? BUY_BONDS_GERMAN_GOV
    : portfolioDataset?.composition?.[compositionIdx]?.category === 'Festgeld'
    ? BUY_FESTGELD
    : portfolioDataset?.composition?.[compositionIdx]?.category === 'Tagesgeld'
    ? BUY_TAGESGELD
    : portfolioDataset?.composition?.[compositionIdx]?.name === 'WERTGRUND WohnSelect D'
    ? BUY_MAT_ASSETS_WERTGRUND
    : null

export const RealizationGuide = () => {
  const store = useAppSelector(selectNavigation)
  const theme = useTheme()
  useUrlParams()
  const portfolio = PORTFOLIO_DATA.portfolios.find((pf) => pf.id === store.strategy_variant)
  const portfolioDataset = {
    ...(portfolio as PortfolioType),
    composition: portfolio?.composition?.map((position) => ({
      ...position,
      ...(PORTFOLIO_DATA.investmentProducts.find((prod) => prod.id === position.id) as InvestmentProductType),
    })),
  }

  const primaryAssetContent = getAssetContent(0, portfolioDataset)
  const secondaryAssetContent = getAssetContent(1, portfolioDataset)
  const filteredPrimaryAssetContent = primaryAssetContent?.filter?.((cont) => !('showCard' in cont))
  const filteredSecondaryAssetContent = secondaryAssetContent?.filter?.((cont) => !('showCard' in cont))
  const getPrimaryStepIdx = (step: StepContent) => filteredPrimaryAssetContent?.indexOf(step) || 0
  const getSecondaryStepIdx = (step: StepContent) => filteredSecondaryAssetContent?.indexOf(step) || 0

  const isMinXlWidth = useMediaQuery(theme.breakpoints.up('xl'))
  const primaryAsset = portfolioDataset?.composition?.[0]
  const primaryAssetCardProps = {
    header: store?.one_time_invest_amount ? `${formatGermanNumber((store?.one_time_invest_amount || 0) * (primaryAsset?.share || 0))}€ ${
      primaryAsset?.category
    }` : `${
      primaryAsset?.category
    }`,
    item:
      (store?.strategy === 'inspirations'
        ? (primaryAsset as SpecificInvestmentProductType)?.name
        : (primaryAsset as AnonymousInvestmentProductType)?.nameAnonymized) ?? '',
    itemLinkHref:
      (store?.strategy === 'inspirations'
        ? (primaryAsset as SpecificInvestmentProductType)?.productLink
        : (primaryAsset as AnonymousInvestmentProductType)?.furtherInfoLink) ?? '#',
    itemDescription: parseStringNewLines(
      (store?.strategy === 'inspirations'
        ? (primaryAsset as SpecificInvestmentProductType)?.description
        : (primaryAsset as AnonymousInvestmentProductType)?.descriptionAnonymized) ?? ''
    ),
    regulationNotice:
      store?.strategy === 'inspirations'
        ? undefined
        : (primaryAsset as AnonymousInvestmentProductType)?.regulationNotice,
    buttonLabel:
      (store?.strategy === 'inspirations'
        ? (primaryAsset as SpecificInvestmentProductType)?.buttonLabel
        : (primaryAsset as AnonymousInvestmentProductType)?.buttonLabelAnonymized) ?? '',
    cardImage: (primaryAsset as SpecificInvestmentProductType)?.cardImage,
  }
  const secondaryAsset = portfolioDataset?.composition?.[1]
  const secondaryAssetCardProps = {
    header: store?.one_time_invest_amount ? `${formatGermanNumber((store?.one_time_invest_amount || 0) * (secondaryAsset?.share || 0))}€ ${
      secondaryAsset?.category
    }`: `${
      secondaryAsset?.category
    }`,
    item:
      (store?.strategy === 'inspirations'
        ? (secondaryAsset as SpecificInvestmentProductType)?.name
        : (secondaryAsset as AnonymousInvestmentProductType)?.nameAnonymized) ?? '',
    itemLinkHref:
      (store?.strategy === 'inspirations'
        ? (secondaryAsset as SpecificInvestmentProductType)?.productLink
        : (secondaryAsset as AnonymousInvestmentProductType)?.furtherInfoLink) ?? '#',
    itemDescription: parseStringNewLines(
      (store?.strategy === 'inspirations'
        ? (secondaryAsset as SpecificInvestmentProductType)?.description
        : (secondaryAsset as AnonymousInvestmentProductType)?.descriptionAnonymized) ?? ''
    ),
    regulationNotice:
      store?.strategy === 'inspirations' && !(secondaryAsset as CategoryInvestmentProductType)?.altText
        ? undefined
        : (secondaryAsset as AnonymousInvestmentProductType)?.regulationNotice,
    buttonLabel:
      (store?.strategy === 'inspirations'
        ? (secondaryAsset as SpecificInvestmentProductType)?.buttonLabel
        : (secondaryAsset as AnonymousInvestmentProductType)?.buttonLabelAnonymized) ?? '',
    cardImage: (secondaryAsset as SpecificInvestmentProductType)?.cardImage,
  }

  const COMPLETED_FINAL_STEPS = React.useMemo(
    () => [
      ...ADDITIONAL_FINAL_STEPS_SAVING_PLAN(
        store?.monthly_invest_amount || 0,
        portfolioDataset?.composition?.[0]?.typeLabel || '',
        portfolioDataset?.composition?.[1]?.typeLabel || '',
        portfolioDataset?.composition?.[0]?.share || 0,
        portfolioDataset?.composition?.[1]?.share || 0
      ),
      ...FINAL_STEPS,
    ],
    [portfolioDataset?.composition, store?.monthly_invest_amount]
  )

  return (
    <Box>
      <ProgressBar progress={100} label="Ihre Step-by-Step Anleitung" pt={isMinXlWidth ? '32px' : 0} />
      <Box
        sx={{
          width: isMinXlWidth ? '775px' : '100%',
          position: 'relative',
          left: isMinXlWidth ? '-200px' : '0px',
        }}
      >
        <Typography variant="h2" paddingTop={'26px'} textAlign={isMinXlWidth ? 'center' : 'left'}>
          So setze ich meine Strategie um:
        </Typography>
        <Box>
          {isMinXlWidth ? (
            <Box>
              <Timeline position="right">
                {FIRST_STEPS.map((step, sIdx) => (
                  <TimelineItem key={sIdx}>
                    <TimelineOppositeContent sx={{ textAlign: 'center', pt: '100px' }}>
                      <Typography variant="customSize" fontWeight={700} lineHeight="185px" fontSize="143px">
                        {sIdx + 1}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      {sIdx !== 0 && (
                        <TimelineConnector
                          sx={{
                            height: '200px',
                            display: 'block',
                            flexGrow: 0,
                            ...(sIdx === 1 ? { position: 'relative', bottom: '+16px', mb: '16px' } : {}),
                          }}
                        />
                      )}
                      <TimelineDot sx={{ mt: !sIdx ? '200px' : '-16px' }}></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ ml: '64px', pt: '100px' }}>
                      <Box>
                        <Box>
                          <RealizationStep
                            header={`Schritt ${sIdx + 1}`}
                            primaryText={step.primaryText}
                            secondaryText={step.secondaryText}
                          />
                        </Box>
                        <LinkButton pt={'12px'} href={step.href}>
                          {step.linkLabel}
                        </LinkButton>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ))}
                {primaryAssetContent?.map?.(
                  (step, sIdx) =>
                    !('showCard' in step) && (
                      <TimelineItem key={sIdx}>
                        <TimelineOppositeContent sx={{ textAlign: 'center', pt: '100px' }}>
                          <Typography variant="customSize" fontWeight={700} lineHeight="185px" fontSize="143px">
                            {FIRST_STEPS.length + 1 + getPrimaryStepIdx(step)}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector sx={{ height: '200px', display: 'block', flexGrow: 0 }} />
                          <TimelineDot></TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ ml: '64px', pt: '100px' }}>
                          <Box key={sIdx}>
                            <Box pt="75px">
                              <RealizationStep
                                header={`Schritt ${FIRST_STEPS.length + 1 + getPrimaryStepIdx(step)}`}
                                primaryText={step.primaryText}
                                secondaryText={step.secondaryText}
                              />
                            </Box>
                            {step?.linkLabel && (
                              <LinkButton pt={'12px'} href={step?.href} to={step?.to}>
                                {step.linkLabel}
                              </LinkButton>
                            )}
                          </Box>
                          {'showCard' in (primaryAssetContent?.[sIdx + 1] ?? {}) && (
                            <Box pt="75px">
                              <PortfolioResultAssetCard {...primaryAssetCardProps} />
                            </Box>
                          )}
                        </TimelineContent>
                      </TimelineItem>
                    )
                )}
                {secondaryAssetContent?.map?.(
                  (step, sIdx) =>
                    !('showCard' in step) && (
                      <TimelineItem key={sIdx}>
                        <TimelineOppositeContent sx={{ textAlign: 'center', pt: '100px' }}>
                          <Typography variant="customSize" fontWeight={700} lineHeight="185px" fontSize="143px">
                            {FIRST_STEPS.length +
                              1 +
                              (filteredPrimaryAssetContent?.length || 0) +
                              getSecondaryStepIdx(step)}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector sx={{ height: '200px', display: 'block', flexGrow: 0 }} />
                          <TimelineDot></TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ ml: '64px', pt: '100px' }}>
                          <Box key={sIdx}>
                            <Box pt="75px">
                              <RealizationStep
                                header={`Schritt ${
                                  FIRST_STEPS.length +
                                  1 +
                                  (filteredPrimaryAssetContent?.length || 0) +
                                  getSecondaryStepIdx(step)
                                }`}
                                primaryText={step.primaryText}
                                secondaryText={step.secondaryText}
                              />
                            </Box>
                            {step?.linkLabel && (
                              <LinkButton pt={'12px'} href={step?.href} to={step?.to}>
                                {step.linkLabel}
                              </LinkButton>
                            )}
                          </Box>
                          {'showCard' in (secondaryAssetContent?.[sIdx + 1] ?? {}) && (
                            <Box pt="75px">
                              <PortfolioResultAssetCard {...secondaryAssetCardProps} />
                            </Box>
                          )}
                        </TimelineContent>
                      </TimelineItem>
                    )
                )}
                {COMPLETED_FINAL_STEPS.map((step, sIdx) => (
                  <TimelineItem key={sIdx}>
                    <TimelineOppositeContent sx={{ textAlign: 'center', pt: '100px' }}>
                      <Typography variant="customSize" fontWeight={700} lineHeight="185px" fontSize="143px">
                        {FIRST_STEPS.length +
                          (filteredPrimaryAssetContent?.length || 0) +
                          (filteredSecondaryAssetContent?.length || 0) +
                          1 +
                          sIdx}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector sx={{ height: '200px', display: 'block', flexGrow: 0 }} />
                      <TimelineDot></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ ml: '64px', pt: '100px' }}>
                      <Box pt="75px">
                        <RealizationStep
                          header={`Schritt ${
                            FIRST_STEPS.length +
                            (filteredPrimaryAssetContent?.length || 0) +
                            (filteredSecondaryAssetContent?.length || 0) +
                            1 +
                            sIdx
                          }`}
                          primaryText={step.primaryText}
                          secondaryText={step.secondaryText}
                        />
                      </Box>
                      {step?.linkLabel && (
                        <LinkButton pt={'12px'} href={step?.href} to={step?.to}>
                          {step.linkLabel}
                        </LinkButton>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Box>
          ) : (
            <Box maxWidth="375px">
              {FIRST_STEPS.map((step, sIdx) => (
                <Box key={sIdx}>
                  <Box pt={!sIdx ? '45px' : '75px'}>
                    <RealizationStep
                      header={`Schritt ${sIdx + 1}`}
                      primaryText={step.primaryText}
                      secondaryText={step.secondaryText}
                    />
                  </Box>
                  <LinkButton pt={'12px'} href={step.href}>
                    {step.linkLabel}
                  </LinkButton>
                </Box>
              ))}
              {primaryAssetContent?.map?.((step, sIdx) =>
                !('showCard' in step) ? (
                  <Box key={sIdx}>
                    <Box pt="75px">
                      <RealizationStep
                        header={`Schritt ${FIRST_STEPS.length + 1 + getPrimaryStepIdx(step)}`}
                        primaryText={step.primaryText}
                        secondaryText={step.secondaryText}
                      />
                    </Box>
                    {step?.linkLabel && (
                      <LinkButton pt={'12px'} href={step?.href} to={step?.to}>
                        {step.linkLabel}
                      </LinkButton>
                    )}
                  </Box>
                ) : (
                  <Box pt="75px" key={sIdx}>
                    <PortfolioResultAssetCard {...primaryAssetCardProps} />
                  </Box>
                )
              )}
              {secondaryAssetContent?.map?.((step, sIdx) =>
                !('showCard' in step) ? (
                  <Box key={sIdx}>
                    <Box pt="75px">
                      <RealizationStep
                        header={`Schritt ${
                          FIRST_STEPS.length +
                          1 +
                          (filteredPrimaryAssetContent?.length || 0) +
                          getSecondaryStepIdx(step)
                        }`}
                        primaryText={step.primaryText}
                        secondaryText={step.secondaryText}
                      />
                    </Box>
                    {step?.linkLabel && (
                      <LinkButton pt={'12px'} href={step?.href} to={step?.to}>
                        {step.linkLabel}
                      </LinkButton>
                    )}
                  </Box>
                ) : (
                  <Box pt="75px" key={sIdx}>
                    <PortfolioResultAssetCard {...secondaryAssetCardProps} />
                  </Box>
                )
              )}
              {COMPLETED_FINAL_STEPS.map((step, sIdx) => (
                <Box key={sIdx}>
                  <Box pt="75px">
                    <RealizationStep
                      header={`Schritt ${
                        FIRST_STEPS.length +
                        (filteredPrimaryAssetContent?.length || 0) +
                        (filteredSecondaryAssetContent?.length || 0) +
                        1 +
                        sIdx
                      }`}
                      primaryText={step.primaryText}
                      secondaryText={step.secondaryText}
                    />
                  </Box>
                  {step?.linkLabel && (
                    <LinkButton pt={'12px'} href={step?.href} to={step?.to}>
                      {step.linkLabel}
                    </LinkButton>
                  )}
                </Box>
              ))}
              <Box pt="75px">
                <RealizationStep
                  header={`Schritt ${
                    FIRST_STEPS.length +
                    (filteredPrimaryAssetContent?.length || 0) +
                    (filteredSecondaryAssetContent?.length || 0) +
                    COMPLETED_FINAL_STEPS.length +
                    1
                  }`}
                />
              </Box>
            </Box>
          )}
          <Box pt="12px">
            {isMinXlWidth ? (
              <Typography fontWeight={700} textAlign="center" pb="48px" mt="100px">
                Den Robo-Advisor weiterempfehlen!
              </Typography>
            ) : (
              <Box>
                <ShareWithFriends />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
