/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { PortfolioIdType } from '../../PORTFOLIO_CONFIG'
import { RootState } from '../store'

export interface NavigationStateType {
  objective: 'save' | 'invest' | 'retirement' | null
  one_time_invest_amount: number | null
  monthly_invest_amount: number | null
  strategy: 'professionals' | 'inspirations' | null
  strategy_variant: PortfolioIdType | null
  start_date: string
  id: string
}

const defaultInitState = {
  objective: null,
  one_time_invest_amount: null,
  monthly_invest_amount: null,
  strategy: null,
  strategy_variant: null,
  start_date: new Intl.DateTimeFormat('de-DE', { dateStyle: 'medium', timeStyle: 'medium' }).format(new Date()),
  id: uuidv4(),
}
const initStorage = window.sessionStorage.getItem('navigation')
const initStorageObject = JSON.parse(initStorage ?? '{}')

const initialState: NavigationStateType = {
  ...defaultInitState,
  ...initStorageObject,
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk('counter/fetchCount', async (amount: number) => {
//   const response = await fetchCount(amount)
//   // The value we return becomes the `fulfilled` action payload
//   return response.data
// })

export const navigationSlice = createSlice({
  name: 'navigate',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setObjective: (state, action: PayloadAction<NavigationStateType['objective']>) => {
      window.sessionStorage.setItem('navigation', JSON.stringify({ ...state, objective: action.payload }))
      state.objective = action.payload
    },
    setAmounts: (
      state,
      action: PayloadAction<{
        one_time_invest_amount: NavigationStateType['one_time_invest_amount']
        monthly_invest_amount: NavigationStateType['monthly_invest_amount']
      }>
    ) => {
      window.sessionStorage.setItem(
        'navigation',
        JSON.stringify({
          ...state,
          one_time_invest_amount: action.payload.one_time_invest_amount,
          monthly_invest_amount: action.payload.monthly_invest_amount,
        })
      )
      state.one_time_invest_amount = action.payload.one_time_invest_amount
      state.monthly_invest_amount = action.payload.monthly_invest_amount
    },
    setStrategy: (state, action: PayloadAction<NavigationStateType['strategy']>) => {
      window.sessionStorage.setItem('navigation', JSON.stringify({ ...state, strategy: action.payload }))
      state.strategy = action.payload
    },
    setStrategyVariant: (state, action: PayloadAction<NavigationStateType['strategy_variant']>) => {
      window.sessionStorage.setItem('navigation', JSON.stringify({ ...state, strategy_variant: action.payload }))
      state.strategy_variant = action.payload
    },
    clearStorage: (state) => {
      state = defaultInitState
      window.sessionStorage.setItem('navigation', JSON.stringify(state))
    },
  },
})

export const { setObjective, setAmounts, setStrategy, setStrategyVariant, clearStorage } = navigationSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNavigation = (state: RootState) => state.navigation

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState())
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount))
//     }
//   }

export default navigationSlice.reducer
