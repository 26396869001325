import React from 'react'
import { Box, Typography, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ProgressBar } from '../components/ProgressBar'
import { PortfolioOptionButton } from '../components/buttons/PortfolioOptionButton'
import { NavigationStateType, selectNavigation, setStrategyVariant } from '../redux/reducers/navigation'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { PORTFOLIO_DATA, SpecificPortfolio } from '../PORTFOLIO_CONFIG'
import { parseStringNewLines } from '../utils/utils'
import { appendValuesInGoogleSheet } from '../utils/api'
import { CBackdrop } from '../components/Backdrop'

export const Inspirations = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const navigate = useNavigate()
  const store = useAppSelector(selectNavigation)
  const isMinXlWidth = useMediaQuery(theme.breakpoints.up('xl'))
  const [finished, setFinished] = React.useState(false)

  const handleClickNext = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement> & { target: { name: NavigationStateType['strategy_variant'] } }) => {
      const name = e?.target?.name ?? null
      if (!name) return
      setFinished(true)
      dispatch(setStrategyVariant(name))
      await appendValuesInGoogleSheet(store, name)
      const urlParams = `?ziel=${store?.objective}&cat=${store?.strategy}&variant=${name}&einmalig=${store.one_time_invest_amount ?? 0}&monatlich=${store.monthly_invest_amount ?? 0}`
      window.location.href = `/result/${urlParams}`
    },
    [navigate, dispatch, store]
  )

  return (
    <Box>
      <ProgressBar progress={80} label="Sehen, was andere machen" />
      <Typography variant="h2" paddingTop={'40px'}>
        Andere Kunden investieren in...
      </Typography>
      <Stack gap={'11px'} mt={isMinXlWidth ? '64px' : '113px'}>
        {(PORTFOLIO_DATA.portfolios.filter((pf) => pf.type === 'inspirations') as SpecificPortfolio[]).map(
          (pf, pIdx) => (
            <PortfolioOptionButton
              key={pIdx}
              onClick={handleClickNext}
              secondaryLabel={parseStringNewLines(pf.description)}
              name={pf.id}
            >
              {pf?.nameInspirationVariants ?? pf.name}
            </PortfolioOptionButton>
          )
        )}
      </Stack>
      <CBackdrop open={finished} />
    </Box>
  )
}
