import React from 'react'
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  OutlinedInputProps,
  Typography,
  FormHelperText,
} from '@mui/material'

export type InputFieldProps = OutlinedInputProps & { helperText?: string }

export const InputField = (props: InputFieldProps) => {
  const { label, helperText, ...rest } = props
  return (
    <FormControl sx={{ width: '100%' }} variant="outlined">
      {label && (
        <Typography variant="caption" paddingBottom="10px">
          {label}
        </Typography>
      )}
      <OutlinedInput
        sx={{
          fontSize: '18px',
          lineHeight: '23px',
          height: 51,
          '& fieldset': {
            borderColor: (theme) => theme.palette.primary.main,
            borderRadius: '4px',
          },
          '&: hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => theme.palette.primary.main,
            },
          },
        }}
        endAdornment={
          <InputAdornment position="end" disableTypography sx={{ color: '#1A2C58' }}>
            €
          </InputAdornment>
        }
        {...rest}
      />
      {helperText && (
        <FormHelperText>
          <Typography variant="smallPrint" color="error.main"> {helperText}</Typography>
        </FormHelperText>
      )}
    </FormControl>
  )
}
