import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ProgressBar } from '../components/ProgressBar'
import { OptionButton } from '../components/buttons/OptionButton'
import { useAppDispatch } from '../redux/hooks'
import { setStrategy } from '../redux/reducers/navigation'
import { scrollToTop } from '../utils/utils'

export const InvestmentStrategy = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleClickProfessionals = React.useCallback(() => {
    dispatch(setStrategy('professionals'))
    navigate('/professionals')
    scrollToTop()
  }, [navigate, dispatch])
  const handleClickInspirations = React.useCallback(() => {
    dispatch(setStrategy('inspirations'))
    navigate('/inspirations')
    scrollToTop()
  }, [navigate, dispatch])

  return (
    <Box>
      <ProgressBar progress={60} label="Eine Strategie entwickeln" />

      <Typography variant="h2" paddingTop={'40px'}>
        Für die Strategie...
      </Typography>
      <Stack gap={'12px'} mt={'145px'}>
        <OptionButton option="A" secondaryLabel=" " onClick={handleClickProfessionals}>
          von Profis konstruierte Portfolios nutzen.
        </OptionButton>
        <OptionButton option="B" secondaryLabel=" " onClick={handleClickInspirations}>
          von anderen Kunden inspiriert werden.
        </OptionButton>
      </Stack>
    </Box>
  )
}
