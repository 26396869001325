import React from 'react'
import { Stack, Typography, Link } from '@mui/material'
import { CButton } from '../components/buttons/Button'
import { InputField } from '../components/InputField'
import { PortfolioOptionButton } from '../components/buttons/PortfolioOptionButton'
import { ProgressBar } from '../components/ProgressBar'
import { OptionButton } from '../components/buttons/OptionButton'

export const Playground = () => (
  <Stack gap={2}>
    {/* Typography */}
    <Typography variant="h1">Der Robo-Advisor</Typography>
    <Typography variant="h2">Das Ziel ist...</Typography>
    <Typography variant="caption">Wählen Sie eine Anlageform</Typography>
    <Typography>Aktienrente starten!</Typography>
    <Typography variant="smallPrint">Impressum</Typography>
    <Link>Zum Invest-O-Mat</Link>

    {/* Buttons */}
    <CButton>Absenden</CButton>
    <CButton type="secondary">Absenden</CButton>
    <OptionButton option="B">Sachwerte aufrunden</OptionButton>
    <OptionButton option="C" secondaryLabel="So gering mir wichtiger als hohe Renditechancen.">
      Sachwerte aufrunden
    </OptionButton>
    <PortfolioOptionButton
      secondaryLabel={
        <>
          Das Portfolio mit dem besten Preis-Wert Verhältnis und einer hohen Dividendenrendite.
          <br />
          <br />
          Kunden, die mehr Wert für ihren Preis rausholen wollen.
        </>
      }
    >
      Das Preiswert-Depot
    </PortfolioOptionButton>
    {/* Inputs */}
    <InputField />
    {/* Other */}
    <ProgressBar progress={40} />
  </Stack>
)
