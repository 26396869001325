import React from 'react'
import { Box, Typography, Stack, Alert, Snackbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LinkButton } from '../components/buttons/LinkButton'
import { ProgressBar } from '../components/ProgressBar'
import { InputField } from '../components/InputField'
import { CButton } from '../components/buttons/Button'
import { useAppDispatch } from '../redux/hooks'
import { formatGermanNumber, parseLocaleNumber, scrollToTop } from '../utils/utils'
import { setAmounts } from '../redux/reducers/navigation'

const errorMessages = [
  'Bitte geben Sie einen einmaligen Investment-Betrag über 100€ ein',
  'Bitte geben Sie einen monatlichen Investment-Betrag über 10€ ein',
]

const SnackbarAnchorOrigin = {
  vertical: 'bottom' as const,
  horizontal: 'center' as const,
}

const isErrorOneTimeInvest = (one_time_invest_amount: number) =>
  typeof one_time_invest_amount !== 'number' ||
  Number.isNaN(one_time_invest_amount) ||
  (typeof one_time_invest_amount === 'number' && one_time_invest_amount < 100)

const isErrorMonthlyInvest = (monthly_invest_amount: number) =>
  typeof monthly_invest_amount === 'number' &&
  (Number.isNaN(monthly_invest_amount) || (!Number.isNaN(monthly_invest_amount) && monthly_invest_amount < 10))

export const InvestmentAmount = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [data, setData] = React.useState({ one_time_invest_amount: '', monthly_invest_amount: '' })
  const [ui, setUi] = React.useState<{
    errorText: string | null
    showErrorInFields: boolean
  }>({ errorText: null, showErrorInFields: false })

  const handleChangeNumber = React.useCallback((e: any) => {
    const { name, value } = e.target
    if (typeof value !== 'string') return
    const valueUngrouped = value.replaceAll('.', '')
    const matches = valueUngrouped?.match(/,/g)
    const matchesDots = valueUngrouped?.match(/\./g)
    if ((matches?.length || 0) > 1 || (matchesDots?.length || 0) > 1) return
    let isNumeric = true
    for (let i = 0; i < valueUngrouped?.length || 0; i += 1) {
      if (
        ![
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '0',
          ',',
          // '.',
        ]?.includes(valueUngrouped?.substring(i, i + 1))
      ) {
        isNumeric = false
        break
      }
    }
    if (!isNumeric) return
    const commaPos = valueUngrouped?.indexOf(',')
    const valueInteger = parseInt(valueUngrouped.slice(0, commaPos !== -1 ? commaPos : undefined), 10)
    const decFraction = commaPos !== -1 ? valueUngrouped.slice(commaPos, commaPos + 3) : ''
    const valueNumber = valueInteger // parseFloat(valueInteger)
    const valueAdj = !Number.isNaN(valueNumber) ? formatGermanNumber(valueNumber) + decFraction : ''
    setData((current) => ({
      ...current,
      [name]: valueAdj,
    }))
  }, [])

  const handleClickNext = React.useCallback(() => {
    /* eslint-disable @typescript-eslint/naming-convention */
    const one_time_invest_amount = parseLocaleNumber(data?.one_time_invest_amount)
    const monthly_invest_amount = parseLocaleNumber(data?.monthly_invest_amount)
    if (isErrorOneTimeInvest(one_time_invest_amount) && isErrorMonthlyInvest(monthly_invest_amount)) {
      setUi((current) => ({
        ...current,
        errorText: 'Bitte geben Sie entweder einen einmaligen oder monatlichen Investment-Betrag an',
        showErrorInFields: true,
      }))
      return
    }
    /* eslint-enable @typescript-eslint/naming-convention */
    dispatch(
      setAmounts({
        one_time_invest_amount: one_time_invest_amount || 0,
        monthly_invest_amount: monthly_invest_amount || 0,
      })
    )
    navigate('/strategy')
    scrollToTop()
  }, [navigate, data?.monthly_invest_amount, data?.one_time_invest_amount, dispatch])

  const handleSnackbarClose = React.useCallback(() => {
    setUi((current) => ({ ...current, errorText: null }))
  }, [])

  return (
    <Box>
      <ProgressBar progress={40} label="Den Betrag bestimmen" />

      <Typography variant="h2" paddingTop={'40px'}>
        Der Investment-Betrag ist:
      </Typography>
      <Stack gap={'32px'} mt={'32px'}>
        <InputField
          label="Einmalig"
          placeholder="5.000,00"
          name="one_time_invest_amount"
          value={data?.one_time_invest_amount}
          onChange={handleChangeNumber}
          inputProps={{ sx: { height: 51, boxSizing: 'border-box' } }}
          error={ui?.showErrorInFields && isErrorOneTimeInvest(parseLocaleNumber(data?.one_time_invest_amount))}
          helperText={
            ui?.showErrorInFields && isErrorOneTimeInvest(parseLocaleNumber(data?.one_time_invest_amount))
              ? errorMessages[0]
              : undefined
          }
        />
        <InputField
          label="Monatlich"
          placeholder="100,00"
          name="monthly_invest_amount"
          value={data?.monthly_invest_amount}
          onChange={handleChangeNumber}
          inputProps={{ sx: { height: '51px', boxSizing: 'border-box' } }}
          error={ui?.showErrorInFields && isErrorMonthlyInvest(parseLocaleNumber(data?.monthly_invest_amount))}
          helperText={
            ui?.showErrorInFields && isErrorMonthlyInvest(parseLocaleNumber(data?.monthly_invest_amount))
              ? errorMessages[1]
              : undefined
          }
        />
      </Stack>
      <LinkButton pt={'40px'} href="https://www.villiam.eu/o-wissen/vermogensbilanz">
        Wie man eine Vermögensbilanz erstellt
      </LinkButton>
      <CButton sx={{ mt: '45px' }} onClick={handleClickNext}>
        Weiter
      </CButton>
      <Snackbar
        open={!!ui?.errorText}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={SnackbarAnchorOrigin}
      >
        <Alert
          severity="error"
          sx={{
            width: '100%',
            borderColor: 'error.main',
            borderWidth: 1,
            borderStyle: 'solid',
            textAlign: 'center',
            alignItems: 'center',
          }}
          onClose={handleSnackbarClose}
        >
          {ui?.errorText}
        </Alert>
      </Snackbar>
    </Box>
  )
}
