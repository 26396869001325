import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LinkButton } from '../components/buttons/LinkButton'
import { ProgressBar } from '../components/ProgressBar'
import { OptionButton } from '../components/buttons/OptionButton'
import { useAppDispatch } from '../redux/hooks'
import { setObjective, type NavigationStateType } from '../redux/reducers/navigation'
import { scrollToTop } from '../utils/utils'

export const InvestmentObjective = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleClickNext = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement> & { target: { name: NavigationStateType['objective'] } }) => {
      const name = e?.target?.name ?? null
      if (!name) return
      dispatch(setObjective(name))
      navigate('/amount')
      scrollToTop()
    },
    [navigate, dispatch]
  )

  return (
    <Box>
      <ProgressBar progress={20} label="Wählen Sie eine Anlageform" />

      <Typography variant="h2" paddingTop={'40px'}>
        Das Ziel ist...
      </Typography>
      <Stack gap={'12px'} mt={'113px'}>
        <OptionButton option="A" onClick={handleClickNext} name="save">
          Sparen
        </OptionButton>
        <OptionButton option="B" onClick={handleClickNext} name="invest">
          Investieren
        </OptionButton>
        <OptionButton option="C" onClick={handleClickNext} name="retirement">
          Altersvorsorge
        </OptionButton>
      </Stack>
      <LinkButton pt={'67px'} href="https://www.villiam.eu/o-wissen/vermoegensillusion">
        Warum Vermögensanlage so wichtig ist
      </LinkButton>
    </Box>
  )
}
