import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { CLayout } from './layout/Layout'
import { ActiveFondCriteria } from './pages/08_ActiveFondCriteria'
import { EtfCriteria } from './pages/07_EtfCriteria'
import { Home } from './pages/00_Home'
import { Inspirations } from './pages/04b_Inspirations'
import { InvestmentAmount } from './pages/02_InvestmentAmount'
import { InvestmentObjective } from './pages/01_InvestmentObjective'
import { InvestmentStrategy } from './pages/03_InvestmentStrategy'
import { Playground } from './pages/Playground'
import { ProfessionalVariants } from './pages/04a_ProfessionalVariants'
import { RealizationGuide } from './pages/06_RealizationGuide'
import { Result } from './pages/05_Result'

function App() {
  return (
    <CLayout>
      <Routes>
        <Route path="/objective" element={<InvestmentObjective />}/>
        <Route path="/amount" element={<InvestmentAmount />} />
        <Route path="/strategy" element={<InvestmentStrategy />} />
        <Route path="/professionals" element={<ProfessionalVariants />} />
        <Route path="/inspirations" element={<Inspirations />} />
   
        <Route path="/result/*" element={<Result />} />
        <Route path="/steps/*" element={<RealizationGuide />} />
        <Route path="/etf-criteria/*" element={<EtfCriteria />} />
        <Route path="/active-fond-criteria/*" element={<ActiveFondCriteria />} />

        <Route path="/dev-playground" element={<Playground />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </CLayout>
  )
}

export default App
