import React from 'react'
import { Typography, Box, Link } from '@mui/material'

export const RiskAdvisoryProjection = () => (
  <>
    <Box pt="32px">
      <Typography color="#8A95AD" component="span" fontWeight={700} variant="smallPrint" sx={{ lineHeight: '15px' }}>
        Risikohinweis:{` `}
      </Typography>
      <Typography color="#8A95AD" component="span" variant="smallPrint" sx={{ lineHeight: '15px' }}>
        Die dargestellten Zielrenditen sind simuliert und sind kein verlässlicher Indikator für zukünftige
        Wertentwicklungen. Geldanlagen an Kapitalmärkten sind mit Risiken verbunden.
      </Typography>
    </Box>

    <Link
      color="primary"
      variant="smallPrint"
      sx={{ textDecoration: 'underline', display: 'block', width: 'max-content', maxWidth: '100%' }}
      href="https://villiam-landingpage.webflow.io/risikohinweis"
      target="_blank"
      rel="noopener noreferrer"
    >
      Bitte beachten Sie unsere Risikohinweise.
    </Link>
  </>
)
