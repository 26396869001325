import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ChartData,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { formatGermanNumber } from '../utils/utils'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, Tooltip)

export const options = {
  responsive: true,
  locale: 'de-DE',
  plugins: {
    legend: {
      position: 'bottom' as const,
      align: 'start' as const,
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
    tooltip: {
      enabled: true,
      // xAlign: 'right' as const,
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || ''
          const DescriptionLabel = label ? `${label}: ` : ''
          return `${DescriptionLabel}${context.formattedValue}€`
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        // color: 'red',
        // borderColor: 'grey',
        // tickColor: 'grey',
        display: false,
      },
    },
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: (value: any) => `${formatGermanNumber(value)}€`,
      },
    },
  },
}

export const LineChart = (props: { data: ChartData<'line'>; height?: any }) => {
  const { data, height } = props
  return <Line options={options} data={data} height={height} />
}

ChartJS.defaults.font.size = 10
ChartJS.defaults.font.family = "'Plus Jakarta Display', sans-serif"
