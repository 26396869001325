import React from 'react'
import { Box, Container, IconButton, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { To, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import logo from '../assets/logo_neu.svg'
import { Banner } from '../components/Banner'
import { BackButton } from '../components/buttons/BackButton'
import { useAppSelector } from '../redux/hooks'
import { clearStorage, NavigationStateType, selectNavigation } from '../redux/reducers/navigation'
import refresh from '../assets/refresh.svg'
import { scrollToTop } from '../utils/utils'
import { DesktopBackground } from './DesktopBackground'
import { CopyToClipboardButton, SocialMediaIcons } from '../components/content/ShareWithFriends'
import { ShareDialog } from '../components/ShareDialog'

export type CLayoutProps = { children?: React.ReactNode }

export const getBackLocation = (path: string, store: NavigationStateType) =>
  path === '/objective'
    ? '/'
    : path === '/amount'
    ? '/objective'
    : path === '/strategy'
    ? '/amount'
    : ['/inspirations', '/professionals'].includes(path)
    ? '/strategy'
    : path.includes('/result') && store?.strategy === 'professionals'
    ? '/professionals'
    : path.includes('/result') && store?.strategy === 'inspirations'
    ? '/inspirations'
    : ['/etf-criteria', '/active-fond-criteria'].includes(path) || path.includes('/steps')
    ? -1
    : '/'

export const Impressum = () => (
  <>
    <Box>
      <Typography variant="smallPrint">
        <Link href="https://www.villiam.eu/impressum" target="_blank" rel="noopener noreferrer">
          Impressum
        </Link>{' '}
        |{' '}
        <Link href="https://www.villiam.eu/data-privacy" target="_blank" rel="noopener noreferrer">
          Datenschutz
        </Link>{' '}
        |{' '}
        <Link href="https://www.villiam.eu/risikohinweis" target="_blank" rel="noopener noreferrer">
          Risikohinweise
        </Link>{' '}
        |{' '}
        <Link href="https://www.villiam.eu/feedback-form" target="_blank" rel="noopener noreferrer">
          Kontakt
        </Link>
      </Typography>
    </Box>

    {/* <IconButton sx={{ p: 0.5, color: 'primary.main' }} onClick={props?.onOpenShareDlg}>
      <img src={share} alt="share" />
    </IconButton> */}
  </>
)

export const CLayout = (props: CLayoutProps) => {
  const { children } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const store = useAppSelector(selectNavigation)
  const backLocation = getBackLocation(location?.pathname, store)
  const theme = useTheme()
  const [ui, setUi] = React.useState({ isOpenShareDlg: false })

  const handleClickBackButton = React.useCallback(() => {
    navigate(backLocation as To) // typescript accepts navigate(string) and navigate(number) but not navigate(string | number)
    scrollToTop()
  }, [backLocation, navigate])

  const handleToggleShareDlg = React.useCallback(() => {
    setUi((current) => ({ ...current, isOpenShareDlg: !current?.isOpenShareDlg }))
  }, [])

  const handleRestartApp = React.useCallback(() => {
    dispatch(clearStorage())
    navigate('/')
    scrollToTop()
  }, [dispatch, navigate])

  const hasLargeHeight = useMediaQuery('(min-height:967px)')
  const isMaxXxsWidth = useMediaQuery('(max-width:320px)')
  const isMinXlWidth = useMediaQuery(theme.breakpoints.up('xl'))
  const isMinLgWidth = useMediaQuery(theme.breakpoints.up('lg'))
  const isMinSmWidth = useMediaQuery(theme.breakpoints.up('sm'))
  const [clientHeight, setClientHeight] = React.useState(0)
  const [contentContainerHeight, setContentContainerHeight] = React.useState(0)

  React.useEffect(() => {
    const newClientHeight = document.getElementById('scrollContainer')?.clientHeight ?? 0
    const newScrollHeight = document.getElementById('scrollContainer')?.scrollHeight ?? 0
    const contentContainerHeight1 = document.getElementById('contentContainer')?.clientHeight ?? 0
    setClientHeight(newClientHeight)
    setContentContainerHeight(newScrollHeight > newClientHeight ? contentContainerHeight1 : newScrollHeight)
  }, [location?.pathname])

  React.useEffect(() => {
    const onResize = () => {
      const newClientHeight = document.getElementById('scrollContainer')?.clientHeight ?? 0
      const newScrollHeight = document.getElementById('scrollContainer')?.scrollHeight ?? 0
      const contentContainerHeight1 = document.getElementById('contentContainer')?.clientHeight ?? 0
      setClientHeight(newClientHeight)
      setContentContainerHeight(newScrollHeight > newClientHeight ? contentContainerHeight1 : newScrollHeight)
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const menuHeight = isMinLgWidth ? 125 : 85
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',

        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <Box sx={{ height: menuHeight }}>
        <Container
          maxWidth={isMinSmWidth && !isMinLgWidth ? 'sm' : isMinLgWidth && !isMinXlWidth ? 'md' : 'lg'}
          disableGutters
          sx={{ height: menuHeight - 23, bgcolor: '#fff' }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pl: '20px', pb: '18px', pt: isMinLgWidth ? '34px' : '8px' }}
          >
            <img src={logo} width={134} alt="logo" />
            {location?.pathname !== '/' &&
              (!isMinXlWidth ||
                ['/etf-criteria', '/active-fond-criteria'].includes(location?.pathname) ||
                location?.pathname.includes('/result') ||
                location?.pathname.includes('/steps')) && (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="32px"
                  pr={
                    isMaxXxsWidth
                      ? '8px'
                      : ['/etf-criteria', '/active-fond-criteria'].includes(location?.pathname) ||
                        location?.pathname.includes('/result') ||
                        location?.pathname.includes('/steps')
                      ? '28px'
                      : '36px'
                  }
                >
                  <BackButton onClick={handleClickBackButton} />
                  {(['/etf-criteria', '/active-fond-criteria'].includes(location?.pathname) ||
                    location?.pathname.includes('/result') ||
                    location?.pathname.includes('/steps')) &&
                    !isMaxXxsWidth && (
                      <IconButton sx={{ width: 26, height: 26 }} onClick={handleRestartApp}>
                        <img src={refresh} alt="refresh" />
                      </IconButton>
                    )}
                </Stack>
              )}
          </Stack>
        </Container>

        <Banner />
      </Box>
      <Box
        height={`calc(100% - ${menuHeight}px)`}
        id="scrollContainer"
        className="customized-scrollbar"
        sx={{ overflowY: 'auto', position: 'relative' }}
      >
        <Container
          maxWidth={isMinSmWidth && !isMinLgWidth ? 'sm' : isMinLgWidth && !isMinXlWidth ? 'md' : 'lg'}
          disableGutters
          sx={{
            height: '100%',
            display: 'flex',
            width: '100%',
            // maxHeight: hasLargeHeight && isMinSmWidth ? '820px' : 'auto',
            // paddingTop: hasVeryLargeHeight && !['/result', '/steps']?.includes(location?.pathname) ? '10%' : 0,
            position: 'relative',
            // zIndex: 1,
            top: 0,
            zIndex: 100,
          }}
        >
          <DesktopBackground containerScrollHeight={contentContainerHeight ?? 0} />
          <Box
            sx={{
              minHeight: hasLargeHeight && isMinSmWidth ? '820px' : '100%',
              // height: !isMinSmWidth || !hasLargeHeight ? '100%' : '100%',
              width: '100%',
            }}
          >
            <Box
              sx={{
                // minHeight: hasLargeHeight && isMinSmWidth ? '820px' : '100%',
                height:
                  (!isMinSmWidth || !hasLargeHeight) &&
                  !!contentContainerHeight &&
                  contentContainerHeight === clientHeight
                    ? '100%'
                    : 'auto',
                boxShadow: isMinSmWidth ? '0px 4px 20px rgba(26, 44, 88, 0.1)' : 'none',
                borderRadius: isMinSmWidth ? '0px 0px 8px 8px' : 0,
                background: '#fff',
                position: 'relative',
              }}
            >
              <Stack
                sx={{
                  pt: '40px',
                  pl: isMinSmWidth ? 0 : '18px',
                  pr: isMinSmWidth ? 0 : '20px',
                  pb: isMinXlWidth ? '72px' : 0,
                  width: '100%',
                  minHeight: hasLargeHeight && isMinSmWidth ? '820px' : '100%',
                  height: !isMinSmWidth ? '100%' : 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  position: 'relative',
                }}
                maxWidth={375}
                id="contentContainer"
              >
                <Stack position="relative" flexGrow={1} sx={{ justifyContent: { xs: 'flex-start', sm: 'center' } }}>
                  <Box minHeight={hasLargeHeight ? 600 : 'auto'}>{children}</Box>
                </Stack>
                {!isMinXlWidth && (
                  <Stack direction="row" justifyContent="space-between" alignItems="center" pt={'26px'} pb="28px">
                    <Impressum />
                  </Stack>
                )}
              </Stack>
              {isMinXlWidth && (
                <Box
                  sx={{
                    width: '100%',
                    height:
                      ['/etf-criteria', '/active-fond-criteria'].includes(location?.pathname) ||
                      location?.pathname.includes('/result') ||
                      location?.pathname.includes('/steps')
                        ? '72px'
                        : '48px',
                    //   ['/steps', '/etf-criteria', '/inspirations'].includes(location?.pathname) ||
                    //   location?.pathname.includes('/result')
                    //     ? contentContainerHeight
                    //     : '100%',
                    position: 'absolute',
                    overflow: 'hidden',
                    bottom: 0,
                    left: 0, // ((windowSize?.width ?? 0) - theme.breakpoints.values.lg) / 2 ,
                    zIndex: 1,
                    pointerEvents: 'none',
                  }}
                >
                  <Stack
                    position="absolute"
                    top={0}
                    direction="row"
                    justifyContent="space-between"
                    minHeight="26px"
                    // position="absolute"
                    // bottom={
                    //   ['/steps', '/etf-criteria', '/inspirations'].includes(location?.pathname) ||
                    //   location?.pathname.includes('/result')
                    //     ? '16px'
                    //     : '50px'
                    // }
                    // bottom={0}
                    width="100%"
                    pr="18px"
                    pl="18px"
                    sx={{ pointerEvents: 'all' }}
                  >
                    {['/'].includes(location?.pathname) ? (
                      <div />
                    ) : (
                      <Stack direction="row" width="400px" alignItems="center" gap="15px">
                        {['/etf-criteria', '/active-fond-criteria'].includes(location?.pathname) ||
                        location?.pathname.includes('/result') ||
                        location?.pathname.includes('/steps') ? (
                          <SocialMediaIcons />
                        ) : (
                          <BackButton onClick={handleClickBackButton} />
                        )}
                      </Stack>
                    )}
                    {(location?.pathname.includes('/steps') || location?.pathname.includes('/result')) && (
                      <Box width="260px">
                        <CopyToClipboardButton />
                      </Box>
                    )}
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={'15px'} width="400px">
                      <Impressum />
                    </Stack>
                  </Stack>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      <ShareDialog open={ui?.isOpenShareDlg} onClose={handleToggleShareDlg} />
    </Box>
  )
}
