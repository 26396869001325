import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../redux/hooks'
import {
  selectNavigation,
  setAmounts,
  setObjective,
  setStrategy,
  setStrategyVariant,
} from '../redux/reducers/navigation'

export const useUrlParams = () => {
  const dispatch = useDispatch()
  const store = useAppSelector(selectNavigation)

  React.useEffect(() => {
    if (store?.monthly_invest_amount && store?.strategy_variant) return
    const extractUrlValue = (key: string, urlIn?: string) => {
      const url = typeof urlIn === 'undefined' ? window.location.href : urlIn
      const match = url.match(`[?&]${key}=([^&]+)`)
      return match ? match[1] : null
    }
    const target = extractUrlValue('ziel')
    const category = extractUrlValue('cat')
    const variant = extractUrlValue('variant')
    const oneTimeInvest = extractUrlValue('einmalig')
    const monthlyInvest = extractUrlValue('monatlich')
    if (!variant || !oneTimeInvest || !['professionals', 'inspirations']?.includes(category ?? '') || !target) return
    dispatch(setStrategy(category as 'professionals' | 'inspirations'))
    dispatch(setStrategyVariant(variant))
    dispatch(
      setAmounts({
        one_time_invest_amount: parseFloat(oneTimeInvest),
        monthly_invest_amount: monthlyInvest ? parseFloat(monthlyInvest) : 0,
      })
    )
    dispatch(setObjective(target as any))
  }, [])
  return null
}
