import { useMediaQuery, useTheme, Box } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from '../utils/utils'

export type DesktopBackgroundProps = {
  containerScrollHeight: number
}
export const DesktopBackground = (props: DesktopBackgroundProps) => {
  const { containerScrollHeight } = props
  const location = useLocation()
  const theme = useTheme()
  const isMinXlWidth = useMediaQuery(theme.breakpoints.up('xl'))
  const windowSize = useWindowSize()

  return windowSize?.width && isMinXlWidth ? (
    <Box
      sx={{
        width: '100vw',
        height:
          location?.pathname.includes('/steps') || location?.pathname.includes('/result')
            ? containerScrollHeight || '100%'
            : '100%',
        position: 'absolute',
        overflow: 'hidden',
        top: 0,
        left: -((windowSize?.width ?? 0) - theme.breakpoints.values.lg) / 2 - 10,
        zIndex: -1,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: ((windowSize?.width ?? 0) - theme.breakpoints.values.lg) / 2 - 254,
          width: 398,
          height: 398,
          background: 'linear-gradient(216.27deg, rgba(0, 209, 255, 0.3) 13.13%, rgba(0, 103, 224, 0.3) 77.91%)',
          opacity: 0.75,
          borderRadius: '50%',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 525,
          right: ((windowSize?.width ?? 0) - theme.breakpoints.values.lg) / 2 - 398 + 122,
          width: 398,
          height: 398,
          background: 'linear-gradient(216.27deg, rgba(0, 255, 224, 0.2) 13.13%, rgba(0, 90, 224, 0.2) 77.91%)',
          opacity: 0.75,
          borderRadius: '50%',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 525,
          right: ((windowSize?.width ?? 0) - theme.breakpoints.values.lg) / 2 - 398 + 122,
          width: 398,
          height: 398,
          background: 'linear-gradient(216.27deg, rgba(0, 255, 224, 0.2) 13.13%, rgba(0, 90, 224, 0.2) 77.91%)',
          opacity: 0.75,
          borderRadius: '50%',
        }}
      />
      <Box
        position="absolute"
        top={1664}
        // left={-299}
        right={-16}
        width={2043}
        height={2045}
        sx={{
          background: 'linear-gradient(216.27deg, rgba(0, 209, 255, 0.1) 13.13%, rgba(0, 103, 224, 0.1) 77.91%)',
        }}
        borderRadius="50%"
      />
      <Box
        position="absolute"
        right={-94}
        top={1608}
        width={224}
        height={224}
        sx={{
          background: 'linear-gradient(216.27deg, rgba(0, 209, 255, 0.4) 13.13%, rgba(0, 103, 224, 0.4) 77.91%)',
        }}
        borderRadius="50%"
      />
      <Box
        position="absolute"
        top={2375}
        right={-37}
        width={263}
        height={263}
        sx={{
          background: 'linear-gradient(216.27deg, rgba(0, 209, 255, 0.4) 13.13%, rgba(0, 103, 224, 0.4) 77.91%)',
        }}
        borderRadius="50%"
      />
      <Box
        position="absolute"
        top={2321}
        left={41}
        width={183}
        height={183}
        sx={{
          background: 'linear-gradient(216.27deg, rgba(0, 255, 224, 0.4) 13.13%, rgba(0, 90, 224, 0.4) 77.91%)',
          opacity: 0.75,
        }}
        borderRadius="50%"
      />
      <Box
        position="absolute"
        top={2425}
        left={130}
        width={117}
        height={117}
        sx={{
          background: 'linear-gradient(216.27deg, rgba(0, 255, 224, 0.4) 13.13%, rgba(0, 90, 224, 0.4) 77.91%)',
          opacity: 0.75,
        }}
        borderRadius="50%"
      />
      {/* <Box
        position="absolute"
        top={3978}
        left={141}
        width={224}
        height={224}
        sx={{
          background: 'linear-gradient(216.27deg, rgba(0, 209, 255, 0.4) 13.13%, rgba(0, 103, 224, 0.4) 77.91%)',
          // opacity: 0.75,

          // border: '6.05667px solid rgba(134, 255, 248, 0.4)',
        }}
        borderRadius="50%"
      /> */}
    </Box>
  ) : null
}
