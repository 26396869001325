import React from 'react'
import { Button, ButtonProps, Theme, alpha, useTheme, Box, Stack } from '@mui/material'

const defaultOptionStyle = (theme: Theme, secondaryLabel: any): Partial<ButtonProps['sx']> => ({
  borderRadius: '4px',
  maxWidth: 337,
  minHeight: 64,
  fontWeight: 400,
  fontSize: '20px',
  border: '1px solid #2860F6',
  background: alpha(theme.palette.primary.main, 0.2),
  textTransform: 'none',
  boxShadow: 'none',
  // '&: hover': {
  //   boxShadow: 'none',
  //   background: alpha(theme.palette.primary.main, 0.6),
  // },
  '&: hover': {
    // eslint-disable-next-line no-useless-computed-key
    ['@media(pointer: fine)']: {
      boxShadow: 'none',
      background: alpha(theme.palette.primary.main, 0.6),
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media(pointer: coarse)']: {
      boxShadow: 'none',
      background: alpha(theme.palette.primary.main, 0.2),
    },
  },

  color: theme.palette.primary.main,
  justifyContent: 'flex-start',
  gap: secondaryLabel ? '20px' : '16px',
  display: 'flex',
  pt: secondaryLabel ? '15px' : '7px',
  pb: secondaryLabel ? '15px' : '7px',
})

export type OptionButtonProps = ButtonProps & {
  children?: React.ReactNode
  option?: string
  secondaryLabel?: string
}

export const OptionButton = (props: OptionButtonProps) => {
  const { children, sx, option, secondaryLabel, name, ...rest } = props
  const theme = useTheme()

  const optionStyles = React.useMemo(
    () => ({ ...defaultOptionStyle(theme, secondaryLabel), ...sx }),
    [sx, secondaryLabel, theme]
  )

  return (
    <Button sx={optionStyles} name={name} variant="contained" {...rest}>
      <Box
        minWidth={50}
        maxWidth={50}
        alignSelf="stretch"
        minHeight={50}
        bgcolor={alpha(theme.palette.primary.main, 0.2)}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="4px"
        sx={{ pointerEvents: 'none', fontWeight: 400, fontSize: '27px', lineHeight: '35px' }}
      >
        {option ?? 'A'}
      </Box>{' '}
      <Stack alignItems="flex-start" sx={{ pointerEvents: 'none' }}>
        <Box sx={{ lineHeight: '26px', fontSize: '20px', textAlign: 'left', pointerEvents: 'none' }}>{children}</Box>
        {secondaryLabel && (
          <Box
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#1A2C58',
              textAlign: 'left',
              lineHeight: '18px',
              mt: '7px',
              pointerEvents: 'none',
            }}
          >
            {secondaryLabel}
          </Box>
        )}
      </Stack>
    </Button>
  )
}
