import React from 'react'
import { Box, Typography } from '@mui/material'
import { CButton } from '../components/buttons/Button'
import { useUrlParams } from '../utils/navigation'

export const ActiveFondCriteria = () => {
  useUrlParams()
  const handleNavigateToProduct = React.useCallback(() => {
    window.open(
      'https://www.onvista.de/fonds/WERTGRUND-WOHNSELECT-D-D-EUR-DIS-Fonds-DE000A1CUAY0',
      '_blank',
      'noopener,noreferrer'
    )
  }, [])

  return (
    <Box>
      <Typography variant="h3" paddingTop={'0px'}>
        Unsere Kauf-Empfehlung
      </Typography>
      <Typography paddingTop={'24px'}>
        Als Orientierung für einen fairen Kurs gilt der von der KVG veröffentlichte NAV (Netto-Inventarwert).
        <br />
        <br /> Unsere Empfehlung ist, nur maximal 1,5% über dem NAV zu kaufen, also mit Kauflimit zu arbeiten. <br />
        <br />
        {`Den Kurs und NAV finden Sie zum Beispiel bei "onvista.de"`}
      </Typography>

      <CButton sx={{ width: '100%', mt: '209px' }} onClick={handleNavigateToProduct}>
        Weiter zum Fonds
      </CButton>
    </Box>
  )
}
