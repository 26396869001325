import React from 'react'
import { Chart as ChartJS, ArcElement } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import type { ChartProps } from 'react-chartjs-2'

ChartJS.register(ArcElement)
export type PieChartProps = Omit<ChartProps<'pie'>, 'type'>

export const PieChart = (props: PieChartProps) => (
  <Pie {...props} options={{ responsive: true, plugins: { tooltip: { enabled: false } } }} />
)
