/* eslint-disable @typescript-eslint/naming-convention */
import { NavigationStateType } from '../redux/reducers/navigation'

declare const gapi: any

export const appendValuesInGoogleSheet = async (store: NavigationStateType, strategy_variant: string) => {
  const { id, objective, one_time_invest_amount, monthly_invest_amount, strategy, start_date } = store

  const start = start_date
  const body = {
    values: [[id, start, objective, one_time_invest_amount, monthly_invest_amount, strategy, strategy_variant]],
  }
  try {
    await gapi.client.init({
      discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
    })
    const res = await gapi.client.sheets.spreadsheets.values.append({
      spreadsheetId: '12RvPfWhZdVZC01TLd_HiBTlpsznHtg05OjvirgrySWI',
      range: 'Sheet1!A1:G1',
      valueInputOption: 'USER_ENTERED',
      insertDataOption: 'INSERT_ROWS',
      resource: body,
    }) // do not delete this then() block -> otherwise api doesnt work!
    console.log(res)
  } catch (e: any) {
    console.error(e)
  }
}
