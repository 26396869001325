import React from 'react'
import { Typography, Box, Stack, useMediaQuery } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CButton } from '../buttons/Button'
import share from '../../assets/share.svg'
import twitterIcon from '../../assets/twitter.svg'
import fbIcon from '../../assets/facebook.svg'
import linkedinIcon from '../../assets/linkedin.svg'
import whatsappIcon from '../../assets/whatsapp.svg'

const shareLink = 'https://robo.villiam.eu/'

export const SocialMediaIcons = () => {
  const isMaxXxsWidth = useMediaQuery('(max-width:320px)')
  return (
    <Stack direction="row" gap={isMaxXxsWidth ? '18px' : '24px'} height="34px">
      <a href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Frobo.villiam.eu&text=Kostenloser%20Robo-Advisor%20mit%20Modellportfolios%20f%C3%BCr%20die%20Aktienrente`} target="_blank" rel="noopener noreferrer">
        <img src={twitterIcon} alt="share" width="34px" height="auto" />
      </a>
      <a href={`https://www.facebook.com/sharer.php?u=https%3A%2F%2Frobo.villiam.eu`} target="_blank" rel="noopener noreferrer">
        <img src={fbIcon} alt="share" width="34px" />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Frobo.villiam.eu`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={linkedinIcon} alt="share" width="34px" />
      </a>
      <a
        href={`https://api.whatsapp.com/send?text=Kostenloser%20Robo-Advisor%20mit%20Modellportfolios%20f%C3%BCr%20die%20Aktienrente%20https%3A%2F%2Frobo.villiam.eu`}
        data-action="share/whatsapp/share"
        // target="_blank" // will open Whatsapp either way
        // rel="noopener noreferrer"
      >
        <img src={whatsappIcon} alt="share" />
      </a>
    </Stack>
  )
}

export const CopyToClipboardButton = () => {
  const [copied, setCopied] = React.useState(false)
  const handleCopy = React.useCallback(() => {
    setCopied(true)
  }, [])
  return (
    <CopyToClipboard text={shareLink} onCopy={handleCopy}>
      <CButton type="secondary" color={copied ? 'success' : 'primary'} sx={{ width: '100%', fontWeight: 700 }}>
        {copied ? 'In Zwischenablage kopiert' : 'Link kopieren'}
      </CButton>
    </CopyToClipboard>
  )
}

export const ShareWithFriends = () => (
  <>
    <Typography fontWeight={700}>Den Robo-Advisor weiterempfehlen!</Typography>
    <Box pt="15px" pr="19px" pl="20px" pb="24px" border="1px solid #E0E0E0" borderRadius="2px" mt="12px">
      <Stack direction="row" gap="11px">
        <img src={share} alt="share" />
        <Typography fontWeight={700}>Jetzt teilen und Freunden und Familie davon erzählen!</Typography>
      </Stack>
      <Box mt="20px">
        <CopyToClipboardButton />
      </Box>
      <Box pt="29px">
        <SocialMediaIcons />
      </Box>
    </Box>
  </>
)
